import {
  IonAvatar,
  IonButton,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonTitle,
  IonToggle,
  IonToolbar,
} from '@ionic/react';
import { useParams } from 'react-router';
import './RevsharePage.css';

import { RootState } from '../store';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import Topbar from '../components/TopBar';
import { ethers } from 'ethers';
import rscvalve from '../abi/RSC.json';
import getContract from './helpers';
// import { profile } from 'console';
import tokenMeta from "../abi/TokenMetadata.json"
import { CopyButton } from '@web3uikit/core';


const RevsharePage: React.FC = () => {
  const stoken = useSelector((state: RootState) => state.profile.token)
  const pubk = useSelector((state: RootState) => state.profile.pubk)
  const pk = useSelector((state: RootState) => state.profile.pk)
  const rpc = useSelector((state: RootState) => state.profile.rpc)
  const state = useSelector((state: RootState) => state)
  const addressBook = useSelector((state: RootState) => state.site?.metadata?.address_book) //"0x51332C7fFbeb3394E57A138A057c0D4Efc2c7d12";//
  const revenueShare = useSelector((state: RootState) => state.site?.metadata?.revenue_share) //"0x51332C7fFbeb3394E57A138A057c0D4Efc2c7d12";//


  const { name } = useParams<{ name: string; }>();
  const [recipients, setRecipient] = useState<any>([])
  let temp: any = [];

  const ipfsGateway: string = "https://files.xlapp.link/ipfs/";
  const fetchIpfs = async (uri: string) => {
    const metadata = await fetch(uri.replace("ipfs://", ipfsGateway));//"https://cloudflare-ipfs.com/ipfs/"));
    let meta = await metadata.json()
    try {
      meta.url = meta.url.replace("ipfs://", ipfsGateway)//"https://cloudflare-ipfs.com/ipfs/")

    } catch (e) {
      console.log(e)
    }
    return meta;
  }

  const getNfts = async () => {
    // (async () => {
    let allItems: any = []
    // let groups: any = (await getGroups())
    let n: any = [];
    // console.log(groups)
    // for (let x: number = 0; x < groups.groups.length; x++) {
    //   console.log(groups.groups[x].external_id)
    //   //allItems.push({ name: groups.groups[x].name, items: await getGroupItems(groups.groups[x].external_id) })

    //   const abi = sbabi.output.abi;
    const provider = new ethers.providers.JsonRpcProvider(rpc);
    const signer = new ethers.Wallet(pk, provider)
    const nft = new ethers.Contract(state.profile.contractData.metaContract.collection, tokenMeta.abi, signer); //0xaCD477E7d64f0493B07F13C2C104353C5fcc3EE6
    //console.log(await nft.)
    //console.log(await provider.getCode(groups.groups[x].external_id))
    // nftContractReadonly.safeMint(pubk,"ipfs://QmQvpBK5PwBopucRBDWE8w7CoNg7MMJenSigN85LmwDyRG")
    const address = pubk;//"0x47D4eafb1429b8f30842E1E47061Ad3EeC94141E"
    const nft_balance = await nft.balanceOf(address)



    for (let y = 0; y < nft_balance; y++) {
      const token_id = await nft.tokenOfOwnerByIndex(address, y)
      const tokenURI = await nft.tokenURI(token_id)
      console.log("tokenURI ", tokenURI)
      let metadata: any = {}
      try {
        metadata = await fetchIpfs(tokenURI)
        const json: any = metadata;//await metadata.json()
        addRecipient({ address: json.metaToken.metaContract.revenue_share, name: json.metaToken.name, email: "", avatar: json.metaToken.url.replace("ipfs://", ipfsGateway), percent: 20 })
        console.log(json)
        return;

        try {

          // json.name = "name"
          console.log(json)
          json.url = json.metaToken.url.replace("ipfs://", "https://files.xlapp.link/ipfs/")
          //json.metaContract.name
          // json.url = json.metaToken.url

          //json.group = groups.groups[x]; //collection

        } catch (e) {

        }
        json.token_id = token_id.toString();
        //n.push(json)
        addRecipient(json)
        // json.name = ""
        // json.image =""
        //todo????

        console.log("loading")
        //addRecipient(JSON.parse(JSON.stringify(n)))


      } catch (e) {
        console.log(e)
      }
    }



    // }
    console.log("nfts ", n)
    //setItems(allItems)
    //})();
    //setNft([])
    return;
  }

  useEffect(() => {

    (async () => {
      //setRecipient(Object.assign([], []));
      temp = [];
      const book = await getContract(state.site.metadata.address_book, "SoulboundToken.json", state)
      //let n:any = []
      let allSBTokens = parseInt((await book.totalSupply()).toString());
      const mytoken = parseInt((await book.balanceOf(pubk)).toString());
      //setMyTokenCount(mytoken)



      const provider = new ethers.providers.JsonRpcProvider(rpc);
      const signer = new ethers.Wallet(pk, provider)
      const nft = new ethers.Contract(state.site.metadata.collection, tokenMeta.abi, signer); //0xaCD477E7d64f0493B07F13C2C104353C5fcc3EE6
      //console.log(await nft.)
      //console.log(await provider.getCode(groups.groups[x].external_id))
      // nftContractReadonly.safeMint(pubk,"ipfs://QmQvpBK5PwBopucRBDWE8w7CoNg7MMJenSigN85LmwDyRG")
      const address = pubk;//"0x47D4eafb1429b8f30842E1E47061Ad3EeC94141E"
      const nft_balance = await nft.balanceOf(address)

      let p: any = []
      console.log("allSBTokens ", allSBTokens)
      for (let x: number = 0; x < allSBTokens; x++) {
        const r: any = await book.getTokenURI(x);
        //console.log(URL.createObjectURL(r))
        //n.push(r)
        let z = await (await fetch(r)).json()
        z.percent = 100 * 100 / ((allSBTokens + nft_balance) * 100)
        console.log(z)
        p.push(z)
        // .then(res => res.json())
        // .then(console.log)
        //addRecipient(z)


        // setNfts(Object.assign([],n))
      }

      // getNfts() 
      let allItems: any = []
      // let groups: any = (await getGroups())
      let n: any = [];
      // console.log(groups)
      // for (let x: number = 0; x < groups.groups.length; x++) {
      //   console.log(groups.groups[x].external_id)
      //   //allItems.push({ name: groups.groups[x].name, items: await getGroupItems(groups.groups[x].external_id) })

      //   const abi = sbabi.output.abi;


      //console.log("does not reach")
      console.log("nft_balance ", parseInt(nft_balance.toString()))

      // let p:any = []
      for (let y = 0; y < nft_balance; y++) {
        const token_id = await nft.tokenOfOwnerByIndex(address, y)
        const tokenURI = await nft.tokenURI(token_id)
        let metadata: any = {}
        try {
          metadata = await fetchIpfs(tokenURI)
          const json: any = metadata;//await metadata.json()
          p.push({ address: json.metaToken.metaContract.revenue_share, name: json.metaToken.name, email: "", avatar: json.metaToken.url.replace("ipfs://", ipfsGateway), percent: 20 })
          addRecipient(p)
          //addRecipient({address:json.metaToken.metaContract.revenueShare,name:json.metaToken.name,email:"",avatar:json.metaToken.url.replace("ipfs://", ipfsGateway),percent:20})
          console.log(json)
          return;

          try {

            // json.name = "name"
            console.log(json)
            json.url = json.metaToken.url.replace("ipfs://", "https://files.xlapp.link/ipfs/")
            //json.metaContract.name
            // json.url = json.metaToken.url

            //json.group = groups.groups[x]; //collection

          } catch (e) {

          }
          json.token_id = token_id.toString();
          //n.push(json)
          addRecipient(json)
          // json.name = ""
          // json.image =""
          //todo????

          console.log("loading")
          //addRecipient(JSON.parse(JSON.stringify(n)))


        } catch (e) {
          console.log(e)
        }
      }

      for (let x = 0; x < p.length; x++) {
        p[x].percent = 100 * 100 / (allSBTokens * 100)
      }
      //setRecipient(JSON.parse(JSON.stringify(recipients)))
      addRecipient(p)
      //setNfts()

    })();
  }, [])
  const addRecipient = (r: any) => {
    //temp.push(r)
    setRecipient(JSON.parse(JSON.stringify(r)))
  }
  // const getContract = (address: string, a: string) => {
  //   const file = require("../abi/" + a)
  //   //console.log("abi ",file)

  //   const abi = file.abi //JSON.parse(file).output.abi;
  //   const provider = new ethers.providers.JsonRpcProvider(rpc);
  //   //console.log(abi)
  //   //const signer = ethers.Wallet.fromMnemonic(myAccountMnemonic);
  //   const signer = new ethers.Wallet(pk, provider)

  //   const nftContractReadonly = new ethers.Contract(address, abi, signer);
  //   return nftContractReadonly;
  // }
  const deploy = async () => {
    const provider = new ethers.providers.JsonRpcProvider(rpc);
    //factory
    // Use your wallet's private key to deploy the contract
    const privateKey = pk
    const wallet = new ethers.Wallet(privateKey, provider)

    // Read the contract artifact, which was generated by Remix
    //const metadata: any = tokenMetadata; //JSON.parse(tokenMetadata)

    // Set gas limit and gas price, using the default Ropsten provider
    const price = ethers.utils.formatUnits(await provider.getGasPrice(), 'gwei')
    const options = { gasLimit: 10000000, gasPrice: ethers.utils.parseUnits(price, 'gwei') }

    // Deploy the contract
    const factory = new ethers.ContractFactory(rscvalve.abi, rscvalve.data.bytecode.object, wallet)
    const contract = await factory.deploy(options)
    await contract.deployed()
    await contract.initialize(
      pubk,
      pubk,
      pubk,
      "false",
      "true",
      "100",
      "1",
      contract.address,
      [pubk],
      [10000],
      ['test'],
    )

    const rsc = await getContract(contract.address, "RSC.json", state)
    let n: any = []
    let numberOfRecipients = parseInt((await rsc.numberOfRecipients()).toString());
    console.log("numberOfRecipients", numberOfRecipients)
    //let uri: string = "ipfs://" + contractCID;

    console.log(`Deployment successful! Contract Address: ${contract.address}`);
  }
  const setPayout = async () => {
    //const price = ethers.utils.formatUnits(await provider.getGasPrice(), 'gwei')
    const options = { gasLimit: 100000000 } //, gasPrice: ethers.utils.parseUnits(price, 'gwei') 
    console.log("revenueShare ", revenueShare)
    const rsc = await getContract(revenueShare, "RSC.json", state)

    let n: any = []
    console.log("owner ", (await rsc.owner()))
    let numberOfRecipients = parseInt((await rsc.numberOfRecipients()).toString());
    console.log("numberOfRecipients before", numberOfRecipients)

    if (await rsc.owner() === "0x0000000000000000000000000000000000000000") {
      console.log("initializing")
      const y = await rsc.initialize(
        "0x93AAc4e25502dE1dDB2096E9BBc751C628CF1eA4",
        "0x93AAc4e25502dE1dDB2096E9BBc751C628CF1eA4",
        "0x93AAc4e25502dE1dDB2096E9BBc751C628CF1eA4",
        false,
        true,
        1,
        1,
        "0x93AAc4e25502dE1dDB2096E9BBc751C628CF1eA4",
        ["0x47D4eafb1429b8f30842E1E47061Ad3EeC94141E", "0x711356f1401846B2FCC04849980B7761A0F6179c"],
        [5000, 5000],
        ["test", "test"],
        //   options
      )
      console.log(y)
    }

    const i = await rsc.setRecipients(
      ["0x47D4eafb1429b8f30842E1E47061Ad3EeC94141E", "0x711356f1401846B2FCC04849980B7761A0F6179c"],
      [4000, 6000],
      ["test", "test"],
      // options
    )

    console.log(i)
    //console.log(y.wait())
    numberOfRecipients = parseInt((await rsc.numberOfRecipients()).toString());
    console.log("numberOfRecipients after", numberOfRecipients)

  }
  const clone = async () => {
    //
    const factory = getContract("0xCEafdDDE78D3664d49C6710E511D3167E95e9C75", "XLARscValveFactory.json")
    // let  data = [
    //   ["name"],
    //   [pubk],
    //   [pubk],
    //   [true],
    //   [true],
    //   [1],
    //   [[pubk]],
    //   [[10000]],
    //   [["bob"]],
    // ];
    let data =
    {
      name: "name",
      controller: pubk,
      distributor: pubk,
      immutableController: true,
      autoEthDistribution: true,
      minAutoDistributeAmount: 1,
      initialRecipients: [pubk],
      percentages: [10000],
      names: ["bob"]
    }
      ;
    // try{
    const r = await factory.createRSCValve(data)
    console.log("r:", r.logs[0].address)
    // }catch(e){
    //   console.log(e)
    // }
    const receipt = await r.wait();

    console.log(receipt)

    //console.log(await factory.owner())
  }
  console.log(recipients)
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <Topbar />
          <IonTitle>{name}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{name}</IonTitle>
          </IonToolbar>
        </IonHeader>
        {/* <IonLabel>Contract settings</IonLabel>
        <br />
        <IonLabel>Public name of your contract for easy finding it in the list</IonLabel> */}
        {/* <IonList>
          <IonItem>
            <IonLabel>Name</IonLabel>:
            <br />
            <IonInput>test</IonInput>
          </IonItem>
          <IonItem>
            <IonLabel>Distributor Address</IonLabel>:
            <br />
            <IonInput>{pubk}</IonInput>
          </IonItem>
          <IonItem>
            <IonLabel>Automatically distribute ETH</IonLabel>
            <IonToggle slot="end"></IonToggle>
          </IonItem>
          <IonItem>
            <IonLabel>Recipients cannot be changed after contract deployment</IonLabel>
            <IonToggle slot="end"></IonToggle>
          </IonItem>
          <IonItem>
            <IonLabel>Revipient editor Address</IonLabel>:
            <IonInput>{pubk}</IonInput>
          </IonItem>
          <IonItem>
            <IonLabel>Editor of recipients cannot be changed</IonLabel>
            <IonToggle slot="end"></IonToggle>
          </IonItem>
        </IonList>
        <IonButton onClick={addRecipient}>Add Recipient</IonButton> */}
        <IonList style={{ width: "100%", display: "table" }}>
          {recipients.length ? recipients.map((i: any, k: any) =>
            // <IonItem key={k}>
            <div style={{ width: "100%", display: "table-row" }}>
              <div style={{ display: "table-cell" }}>
                <IonAvatar style={{ height: 25, width: 25 }} slot="start">

                  <img src={i.avatar} referrerPolicy="no-referrer" />

                </IonAvatar>
              </div>
              &nbsp;
              <div style={{ display: "table-cell",whiteSpace:"nowrap" }}>
                {/* <span style={{whiteSpace:"nowrap"}}> */}
                
                <CopyButton text={i.address} />
                {/* </span> */}
              </div>
              <div style={{ display: "table-cell" }}>
              <IonInput>{i.address}</IonInput>

              </div>
              <div style={{ display: "table-cell" }}>
                <IonLabel>{i.name}</IonLabel> &nbsp;
              </div>
              <div style={{ display: "table-cell" }}>
                <IonLabel>{i.email}</IonLabel> &nbsp;
              </div>
              <div style={{ display: "table-cell" }}>
                <IonInput>{i.percent.toFixed(2) + '%'}</IonInput>
              </div>
              <div style={{ display: "table-cell" }}>
                <IonToggle />
              </div>
            </div>
            // </IonItem>
          ) : null}
        </IonList>
        <IonButton onClick={setPayout}>Set Recipeints</IonButton>
      </IonContent>
    </IonPage>
  );
};

export default RevsharePage;


