import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { useHistory, useParams } from 'react-router';
import ExploreContainer from '../components/ExploreContainer';
import './ItemsPage.css';

import { RootState } from '../store';
import { useDispatch, useSelector } from 'react-redux';
import {refresh } from '../features/counter/profileSlice';
import { useEffect, useState } from 'react';
import axios from 'axios'
import sbabi from "../abi/TokenMetadata.json"
import { ethers } from 'ethers';

import axiosRetry from "axios-retry"
import FormData  from "form-data";
import Topbar from '../components/TopBar';



const ItemsPage: React.FC = () => {
  const stoken = useSelector((state: RootState) => state.profile.token)
  const { name } = useParams<{ name: string; }>();
  const [items, setItems] = useState([]);
  const history = useHistory();
  const pk = useSelector((state: RootState) => state.profile.pk)
  const pubk = useSelector((state: RootState) => state.profile.pubk)
  const update = useSelector((state: RootState) => state.profile.refresh)
  const rpc = useSelector((state: RootState) => state.profile.rpc)

  const profile = useSelector((state: RootState) => state.profile)

  const dispatch = useDispatch()
  //const refresh = useSelector((state: RootState) => state.profile.refresh)
  const JWT = `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySW5mb3JtYXRpb24iOnsiaWQiOiI0MDFkZjVlMC1mODNhLTQyMDMtOGVjNi00NWJiNmZkZTk4YWEiLCJlbWFpbCI6ImFyc2VuM2RAZ21haWwuY29tIiwiZW1haWxfdmVyaWZpZWQiOnRydWUsInBpbl9wb2xpY3kiOnsicmVnaW9ucyI6W3siaWQiOiJGUkExIiwiZGVzaXJlZFJlcGxpY2F0aW9uQ291bnQiOjF9XSwidmVyc2lvbiI6MX0sIm1mYV9lbmFibGVkIjpmYWxzZSwic3RhdHVzIjoiQUNUSVZFIn0sImF1dGhlbnRpY2F0aW9uVHlwZSI6InNjb3BlZEtleSIsInNjb3BlZEtleUtleSI6ImM4YWUwNDFkNDMxZGRlMDVhZDllIiwic2NvcGVkS2V5U2VjcmV0IjoiNGY2NGIwMGJkZTY5ZDFkNTVmMDZlYTRiOGQ0OTg5ODgzNjU0YjQ1YzU5Y2NiNDk3OWM3MTQ1NWFmNGE4NTVmZiIsImlhdCI6MTY3MDk2NzQwOH0.CAx-o_c-3O8GWlDp_Ag3BRGzJ6gFuv0OYBtjmZwU5qY`
  
  // const handleAssetSubmission = async () => {
  //   const formData: any = new FormData();

  //   formData.append('file', selectedAssetFile)

  //   const metadata = JSON.stringify({
  //     name: 'File name',
  //   });
  //   formData.append('pinataMetadata', metadata);

  //   const options = JSON.stringify({
  //     cidVersion: 0,
  //   })
  //   formData.append('pinataOptions', options);

  //   try {
  //     const res = await axios.post("https://api.pinata.cloud/pinning/pinFileToIPFS", formData, {
  //       //maxBodyLength: "Infinity",
  //       headers: {
  //         'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
  //         Authorization: JWT
  //       }
  //     });
  //     console.log(res.data);
  //     //setSidAssetSelectedFile("ipfs://" + res.data.IpfsHash)
  //     //pinTokenUriJson()
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  const pinTokenUriJson = async (name:string,description:string,ipfs:string,metaContract:any) => {
    var metadata = JSON.stringify({
      "pinataOptions": {
        "cidVersion": 1
      },
      "pinataMetadata": {
        "name": "testing",
        "keyvalues": {
          "customKey": "customValue",
          "customKey2": "customValue2"
        }
      },
      "pinataContent": {
        "name": name,
        "description": description,
        "url": "ipfs://"+ipfs,
        "animation_url": "",
        "external_url": "",
        "background_color": "",
        "youtube_url": "",
        metaContract:metaContract
    }
    });

    var configMetadata = {
      method: 'post',
      url: 'https://api.pinata.cloud/pinning/pinJSONToIPFS',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': JWT
      },
      data: metadata
    };

    const metadataRes = await axios(configMetadata);

    console.log(metadataRes.data);
    return metadataRes.data.IpfsHash
  }
  const uploadToPinata = async (sourceUrl:string) => {
    console.log("sourceUrl",sourceUrl)
    const axiosInstance = axios.create();
  
    axiosRetry(axiosInstance, { retries: 5 });
    const data:any = new FormData();
  
    const response = await axiosInstance(sourceUrl, {
      method: "GET",
      responseType: "blob",
    });
    //data.append(`file`, response.data);
    const formData:any = new FormData();
    
    formData.append('file', response.data)

    const metadata = JSON.stringify({
      name: 'File name',
    });
    formData.append('pinataMetadata', metadata);
    
    const options = JSON.stringify({
      cidVersion: 0,
    })
    formData.append('pinataOptions', options);

            
    console.log("response.data",response.data)
    console.log("formData",formData.getAll("file"))
    //return;
    try {
      const res = await axios.post("https://api.pinata.cloud/pinning/pinFileToIPFS", formData, {
       //maxBodyLength: "Infinity",
        headers: {
            'Content-Type': `multipart/form-data;`,
            'Authorization': JWT
        }
      });
      console.log(res.data);
      return res.data.IpfsHash
      
    } catch (error) {
      console.log(error)
    }
  };
  const  mint = async (i: any) => {

      console.log(i)
      
      if(!window.confirm("Mint")){
        return;
      }
      const ipfsHash = await uploadToPinata(i.image_url)
      const metadataIpfsHash = await pinTokenUriJson(i.name,i.description,ipfsHash,profile.contractData.metaContract)
      
       // setSidTokenUri("ipfs://" + metadataRes.data.IpfsHash)
      const abi = sbabi.abi;
      const provider = new ethers.providers.JsonRpcBatchProvider(rpc);
      const signer = new ethers.Wallet(pk,provider)
      const nftContractReadonly = new ethers.Contract(profile.contractData.metaContract.collection,abi,signer);// i.groups[0].external_id, abi, signer);//"0x62F254699A2f657d56BEE9Bf8C90de536576DDD0"
      const result = await nftContractReadonly.safeMint(pubk,"ipfs://"+metadataIpfsHash)
      console.log(result)
      dispatch(refresh())
      setTimeout(()=>history.push("/page/nfts"),5000)
      //history.push("/page/nfts")
      return;

    //throw new Error('Function not implemented.');
    const query = new URLSearchParams({
      platform: 'playstation_network'
    }).toString();
    

    const projectId = '205984';
    const itemSku = 'sword';
    const virtualCurrencySku = 'YOUR_virtual_currency_sku_PARAMETER';
    const resp = await fetch(
      //`https://store.xsolla.com/api/v2/project/${projectId}/free/item/${itemSku}`,///virtual/${virtualCurrencySku}?${query}`,
      `https://store.xsolla.com/api/v2/project/${projectId}/user/inventory/items`,///virtual/${virtualCurrencySku}?${query}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + stoken
        },
        body: JSON.stringify({
          custom_parameters: {
            array_param: [
              {
                object1: {
                  param: 'https://xsolla.com'
                },
                property: {
                  'int-property': 112,
                  'string-property': 'xsolla'
                }
              },
              {
                object2: {
                  param: 'https://developers.xsolla.com'
                },
                property: {
                  'int-property': 112,
                  'string-property': 'email@example.com'
                }
              }
            ],
            object_param: {key: 'value'},
            bool_param: true,
            int_param: 100,
            null_param: null
          }
        })
      }
    );
    
    const data = await resp.json();
    console.log(data);
  }
  useEffect(() => {
    //https://axios-http.com/docs/example
    //https://medium.com/swlh/interacting-with-restful-apis-using-typescript-react-hooks-and-axios-part-1-af52920ae3e4
    //let axios = new Axios()
    //axios.get('https://store.xsolla.com/api/v2/project/44056/user/inventory/items',
    //205984
    //axios.get('https://store.xsolla.com/api/v2/project/2059842/user/inventory/items',
    //axios.get('https://store.xsolla.com/api/v2/project/88974/user/inventory/items',
    //axios.get('https://store.xsolla.com/api/v2/project/205984/items/virtual_items',
    axios.get('https://store.xsolla.com/api/v2/project/'+window.project_id+'/user/inventory/items',
   
    {
      headers: {
        "authorization": "Bearer " + stoken //"eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE5NjIyMzQwNDgsImlzcyI6Imh0dHBzOi8vbG9naW4ueHNvbGxhLmNvbSIsImlhdCI6MTU2MjE0NzY0OCwidXNlcm5hbWUiOiJ4c29sbGEiLCJ4c29sbGFfbG9naW5fYWNjZXNzX2tleSI6IjA2SWF2ZHpDeEVHbm5aMTlpLUc5TmMxVWFfTWFZOXhTR3ZEVEY4OFE3RnMiLCJzdWIiOiJkMzQyZGFkMi05ZDU5LTExZTktYTM4NC00MjAxMGFhODAwM2YiLCJlbWFpbCI6InN1cHBvcnRAeHNvbGxhLmNvbSIsInR5cGUiOiJ4c29sbGFfbG9naW4iLCJ4c29sbGFfbG9naW5fcHJvamVjdF9pZCI6ImU2ZGZhYWM2LTc4YTgtMTFlOS05MjQ0LTQyMDEwYWE4MDAwNCIsInB1Ymxpc2hlcl9pZCI6MTU5MjR9.GCrW42OguZbLZTaoixCZgAeNLGH2xCeJHxl8u8Xn2aI"
      }}
    )
  .then(function (response) {
    // handle success
    console.log(response);
    setItems(response.data.items)
   // setState
  })

    return;
    var data = null;

    var xhr = new XMLHttpRequest();
    xhr.withCredentials = true;

    xhr.addEventListener("readystatechange", function () {
      if (this.readyState === this.DONE) {
        console.log(this.responseText);
      }
    });

    xhr.open("GET", "https://store.xsolla.com/api/v2/project/44056/user/inventory/items");
    xhr.setRequestHeader("authorization", "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE5NjIyMzQwNDgsImlzcyI6Imh0dHBzOi8vbG9naW4ueHNvbGxhLmNvbSIsImlhdCI6MTU2MjE0NzY0OCwidXNlcm5hbWUiOiJ4c29sbGEiLCJ4c29sbGFfbG9naW5fYWNjZXNzX2tleSI6IjA2SWF2ZHpDeEVHbm5aMTlpLUc5TmMxVWFfTWFZOXhTR3ZEVEY4OFE3RnMiLCJzdWIiOiJkMzQyZGFkMi05ZDU5LTExZTktYTM4NC00MjAxMGFhODAwM2YiLCJlbWFpbCI6InN1cHBvcnRAeHNvbGxhLmNvbSIsInR5cGUiOiJ4c29sbGFfbG9naW4iLCJ4c29sbGFfbG9naW5fcHJvamVjdF9pZCI6ImU2ZGZhYWM2LTc4YTgtMTFlOS05MjQ0LTQyMDEwYWE4MDAwNCIsInB1Ymxpc2hlcl9pZCI6MTU5MjR9.GCrW42OguZbLZTaoixCZgAeNLGH2xCeJHxl8u8Xn2aI");

    xhr.send(data);
  },[update])
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
        <Topbar/>
          <IonTitle>{name}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Inventory</IonTitle>
          </IonToolbar>
        </IonHeader>
        {/* {stoken} */}
    
        {/* {items.length} */}
        {/* {items.map((i:any,k:any)=>{
          return <img key={k} width={100} height={100} alt={i.sku}onClick={()=>buy(i)} src={i.image_url}/> 
        })
        } */}
        <div className='flex-container-nft'>
          {items.map((i: any, k: any) => {
            return (<div className='card' key={k}>
              <img className='nft-img' alt={i.name} src={i.image_url.replace("ipfs.io","cloudflare-ipfs.com")} />
              <br/>
              {i.name}
              <br />
              {i.token_id}
              <button  onClick={() => mint(i)}>Mint</button>
            </div>)
            // return <img   key={x} width={200} height={200} alt={i.sku}onClick={()=>buy(i)} src={i.image_url}/> 
          })
          }
        </div>
        {/* <ExploreContainer name={name} /> */}
      </IonContent>
    </IonPage>
  );
};

export default ItemsPage;


