// import './ExploreContainer.css';

import { IonButtons, IonButton, IonMenuButton } from "@ionic/react";
import { CopyButton } from "@web3uikit/core";
import { useSelector } from "react-redux";
import truncateEthAddress from "truncate-eth-address";
import { RootState } from "../store";

// interface ContainerProps {
//   name: string;
// }

const Topbar: React.FC = () => {
  const pubk: any = useSelector((state: RootState) => state.profile.pubk)
  const profile: any = useSelector((state: RootState) => state.profile.profile)

  return (
    <>
      {profile?.email?.length ?
        <>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons >
         
          <IonButtons slot="end"><IonButton onClick={()=>window.location.assign("/auth/login")}>Logout</IonButton> </IonButtons >
        </>
        :
        <IonButtons slot="end"><IonButton routerLink='/auth/babka/true'>Login</IonButton> </IonButtons >}
    </>
    // <div className="container">
    //   <strong>{name}</strong>
    //   <p>Explore <a target="_blank" rel="noopener noreferrer" href="https://ionicframework.com/docs/components">UI Components</a></p>
    // </div>
  );
};

export default Topbar;
