import { IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { useParams } from 'react-router';
// import './Template.css';

import { RootState } from '../store';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import axios from 'axios'
import { radioButtonOffSharp, radioButtonOnSharp, sendOutline, swapHorizontalSharp } from 'ionicons/icons';
import { ethers } from 'ethers';
import { CopyButton } from '@web3uikit/core';
import truncateEthAddress from 'truncate-eth-address';
//import fetch from 'node-fetch-cache';
import { refresh, rpc as r } from '../features/counter/profileSlice';

interface ContainerProps {
  rpc: string;
  token?:string;
  chain?:string;
}

const Chainlet: React.FC<ContainerProps> = ({ rpc, token, chain }) => {
  const stoken = useSelector((state: RootState) => state.profile.token)
  const { name } = useParams<{ name: string; }>();
  const pk = useSelector((state: RootState) => state.profile.pk)
  const pubk = useSelector((state: RootState) => state.profile.pubk)
  const activeRPC = useSelector((state: RootState) => state.profile.rpc)
  const update = useSelector((state: RootState) => state.profile.refresh)

  const [chainletMetadata, setChainletMetadata] = useState<any>({});
  const dispath = useDispatch()
  // const rpc = useSelector((state: RootState) => state.profile.rpc)
  useEffect(() => {
    // if(token===undefined){
      init()
    // }else{
    //   setChainletMetadata({})
    // }
   
    
   
  }, [update])
  const init=  async () =>{
    const provider = new ethers.providers.JsonRpcProvider(rpc);
    const signer = new ethers.Wallet(pk, provider)
    let networkMetadata;
    if(token===undefined){
       networkMetadata = await (await fetch("https://raw.githubusercontent.com/ethereum-lists/chains/master/_data/chains/eip155-"+(await provider.getNetwork()).chainId+ ".json")).json()
    }else{
      networkMetadata = {chain:"XLA",name:"XLAOS"}
      //setChainletMetadata({})
    }
    
    networkMetadata.balance = parseFloat(ethers.utils.formatEther(await signer.getBalance())).toFixed(3)
    setChainletMetadata(networkMetadata)
    //console.log("network id ",(await provider.getNetwork()).chainId)
    // let utf8Encode = new TextEncoder();
    // utf8Encode.encode("abc");
    // console.log("mnemonic",entropyToMnemonic([ 97, 98, 99 ,97]))

    //console.log("entropyToMnemonic ",await entropyToMnemonic(pk))
   
    //  console.log(signer.provider)
    // let balance = await signer.getBalance()
   // console.log(parseFloat(ethers.utils.formatEther(balance.toString()).toString()).toFixed(3))
   
  }
  const setActiveChainlet = () =>{
    dispath(r(rpc))
    dispath(refresh())

  }
  return (
    <IonCard color="light" onClick={setActiveChainlet}>
      <IonCardHeader>
        <IonCardTitle>
          
          <svg viewBox="0 0 56 28">
            <text x="0" y="15">
            {chainletMetadata.balance}
              {/* {xlaBalance} */}
              {/* {xlaBalance} */}
            </text>
            {/* <text x="0" y="15">{parseFloat(ethers.utils.formatEther(xlaBalance)).toFixed(3)}</text> */}
            {chainletMetadata.balance}
          </svg>
          
        </IonCardTitle>
        <IonCardSubtitle>{chainletMetadata.chain} {truncateEthAddress(pubk)}<CopyButton text={pubk} /> </IonCardSubtitle>
      </IonCardHeader>
      <IonCardContent >
      {/* <IonItem> */}
      {/* <IonIcon  slot='end' onClick={()=>{}} icon={radioButtonOffSharp} /> */}
      <IonLabel slot='start' >{chainletMetadata.name}</IonLabel>
      {/* </IonItem> */}
      </IonCardContent>
      <IonItem>
      {/* <IonIcon onClick={()=>{}} icon={radioButtonOffSharp} /> */}
        {/* <IonIcon onClick={sendWei} icon={sendOutline} /> */}
        <IonIcon slot='end' icon={swapHorizontalSharp} />
      </IonItem>
      {/* <IonButton onClick={sendWei}>Send</IonButton>
            <IonButton>Bridge</IonButton> */}
      {/* <IonButton>Widthdraw</IonButton> */}
    </IonCard>

  );
};
export default Chainlet;


