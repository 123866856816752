import {
  IonAvatar,
  IonButton,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
  IonRouterLink,
} from '@ionic/react';

import { useLocation } from 'react-router-dom';
import { earth, compass, gameControllerSharp, ribbon, person, flash, people, sync, storefront, wallet, cash, diamond, archiveOutline, archiveSharp, bookmarkOutline, heartOutline, heartSharp, mailOutline, mailSharp, paperPlaneOutline, paperPlaneSharp, trashOutline, trashSharp, warningOutline, warningSharp, shareSocial, ribbonOutline, imageOutline, imageSharp, ticketSharp, analyticsSharp, cloudUploadSharp, giftOutline, gift } from 'ionicons/icons';
import './Menu.css';
import { RootState } from '../store';
import { useDispatch, useSelector } from 'react-redux';
import * as ethers from "ethers";
import { gas } from '../features/counter/profileSlice'
import { CopyButton, useNotification } from '@web3uikit/core'
//import EthAddress from 'react-eth-address';
import truncateEthAddress from 'truncate-eth-address'
import { pk, pubk, refresh } from '../features/counter/profileSlice';
import { useEffect, useState } from 'react';
import { loading } from '../features/counter/counterSlice';


interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
  public?: boolean;
  enabled?: boolean;
}




const labels = ['Native', 'Ethereum Main', 'Polygon', 'ZkSync', 'BNB', 'Ave'];

const Menu: React.FC = () => {
  const location = useLocation();
  const profile = useSelector((state: RootState) => state.profile.profile)
  const pubk = useSelector((state: RootState) => state.profile.pubk)
  const wei = useSelector((state: RootState) => state.profile.gas)
  const pk = useSelector((state: RootState) => state.profile.pk)
  const rpc = useSelector((state: RootState) => state.profile.rpc)
  const metadata = useSelector((state: RootState) => state.site.metadata)
  const update = useSelector((state: RootState) => state.profile.refresh)
  const [appPages, setAppPages] = useState<any>([])
  const dispatch = useDispatch()


  useEffect(() => {
    const appPagess: AppPage[] = (metadata==null)?[]:[

      // {
      //   title: 'Cash',
      //   url: '/page/cash',
      //   iosIcon: cash,
      //   mdIcon: cash
      // },
      {
        title: 'Dashboard',
        url: '/',
        iosIcon: person,
        mdIcon: person,
        public: true
      },
      {
        title: 'Coins',
        url: '/page/cash',
        iosIcon: wallet,
        mdIcon: wallet,
        enabled: metadata?.attributes?.features?.coins 
        //attributes.features.coins
      },
      {
        title: 'NFTs',
        url: '/page/nfts',
        iosIcon: imageSharp,
        mdIcon: imageSharp
      },
      {
        title: 'Mint',
        url: '/page/items',
        iosIcon: diamond,
        mdIcon: diamond
      },
  
      {
        title: 'Drops',
        url: '/page/store',
        iosIcon: gift,
        mdIcon: gift,
        enabled: metadata?.attributes?.features?.drops 
  
      },
      {
        title: 'NFT Store',
        url: '/page/nftstore',
        iosIcon: storefront,
        mdIcon: storefront,
        enabled: metadata?.attributes?.features?.nftstore 
      },
      {
        title: 'License',
        url: '/page/deploy',
        iosIcon: flash,
        mdIcon: flash,
        enabled: metadata?.attributes?.features?.license
      },
      {
        title: 'BulkUpload',
        url: '/page/bulkupload',
        iosIcon: cloudUploadSharp,
        mdIcon: cloudUploadSharp
      },
      {
        title: 'RevShare',
        url: '/page/revshare',
        iosIcon: shareSocial,
        mdIcon: shareSocial,
        enabled: metadata?.attributes?.features?.revshare 
      },
      {
        title: 'DigID',
        url: '/page/soulbound',
        iosIcon: ribbon,
        mdIcon: ribbon,
        enabled: metadata?.attributes?.features?.digid 

      },
      // {
      //   title: 'Market',
      //   url: '/page/market',
      //   iosIcon: people,
      //   mdIcon: people
      // },
      {
        title: 'Tickets',
        url: '/page/tickets',
        iosIcon: ticketSharp,
        mdIcon: ticketSharp
      },
      {
        title: 'Analytics',
        url: '/page/analytics',
        iosIcon: analyticsSharp,
        mdIcon: analyticsSharp
      },
      // {
      //   title: 'Games',
      //   url: '/page/games',
      //   iosIcon: gameControllerSharp,
      //   mdIcon: gameControllerSharp
      // },
      {
        title: 'Metaverse',
        url: '/page/metaverse',
        iosIcon: earth,
        mdIcon: earth,
        enabled: metadata?.attributes?.features?.metaverse 
      },
      {
        title: 'Discover',
        url: '/page/discover',
        iosIcon: compass,
        mdIcon: compass
      },
      // {
      //   title: 'Trash',
      //   url: '/page/Trash',
      //   iosIcon: trashOutline,
      //   mdIcon: trashSharp
      // },
      // {
      //   title: 'Community',
      //   url: '/page/messages',
      //   iosIcon: paperPlaneOutline,
      //   mdIcon: paperPlaneSharp
      // },
      // {
      //   title: 'Payouts',
      //   url: '/page/payouts',
      //   iosIcon: cash,
      //   mdIcon: cash
      // },
      // {
      //   title: 'Bridge',
      //   url: '/page/bridge',
      //   iosIcon: sync,
      //   mdIcon: sync
      // },
  
      {
        title: 'Token Gate',
        url: '/page/favorites',
        iosIcon: heartOutline,
        mdIcon: heartSharp
      },
      {
        title: 'settings',
        url: '/page/settings',
        iosIcon: heartOutline,
        mdIcon: heartSharp
      },
  
    ];
    setAppPages(appPagess)
  },[update])

  

  const sendWei = async () => {
    const provider = new ethers.providers.JsonRpcProvider(rpc);
    const signer = new ethers.Wallet(pk, provider)
    let addrs = window.prompt("Destination Address")
    if (addrs) {
      let r = await signer.sendTransaction({
        to: addrs,
        value: 100000//ethers.utils.parseEther('2', 'ether')
      })
      //alert(JSON.stringify(r))
      let xlabal = await signer.getBalance()
      dispatch(gas(xlabal.toString()))
    }

  }
  const updatePages = async () => {
    //dispatch(refresh())

  }
  // const notify = useNotification();
  return (
    <IonMenu contentId="main" type="overlay" onClick={updatePages}>
      <IonHeader>
        <div>
          <div className='logo'  >
            <img alt="XLA" width={100} onClick={() => {
              // if(window.location.host.indexOf("localhost")>0){
              //   window.location.assign(window.location.protocol + "//localhost:" + window.location.port)
              // }
              if (window.location.host.split(".").length == 3) {
                let r = window.location.protocol + "//" + window.location.host.split(".")[1] + "." + window.location.host.split(".")[2]
                console.log(r)
                window.location.assign(r)
              }

            }

            } height={50} src='/assets/xla.svg' />
            <div>
              {truncateEthAddress(pubk)}{pubk.length ? <CopyButton text={pubk} /> : null}
            </div>

          </div>
          <div slot='end'>

          </div>
        </div>
      </IonHeader>
      <IonContent>
        {/* {profile.picture.split("=")[0]} */}

        <IonList style={profile===null ? { display: "none" } : {}}>
          <IonItem>
            <IonRouterLink onClick={()=>{dispatch(refresh())}} routerLink='/page/deploy/new'>
              <div>
                <IonAvatar slot="start">

                  <img src={profile?.avatar} referrerPolicy="no-referrer" />

                </IonAvatar>
              </div>
            </IonRouterLink>
            <br />
            <IonLabel>
              {profile?.name}
              <br />
              {profile?.email}
            </IonLabel>

          </IonItem>
          {/* <IonItem>
            <IonNote> */}
          {/* <EthAddress address={pubk} /> */}
          {/* <IonLabel><CopyButton text={pubk}/>{pubk}</IonLabel> */}
          {/*               
              <br />
              <IonLabel onClick={sendWei}>{wei}</IonLabel>
            </IonNote>

          </IonItem> */}
        </IonList>

        <IonList id="inbox-list">
          {/* <IonAvatar slot="start"><img src={profile.picture.split("=")[0]} /></IonAvatar> */}
          {/* <h1>XLA Workbench (Sandbox)</h1> */}
          {/* {profile.avatar} */}


          {/* <IonNote>{pubk}</IonNote> */}

          {profile!=null && appPages.map((appPage:any, index:any) => {
            return (
              <IonMenuToggle key={index} autoHide={false}>

                {appPage.enabled ? <IonItem disabled={profile.email == null && appPage.public == null} className={location.pathname === appPage.url ? 'selected' : ''} routerLink={appPage.url} routerDirection="none" lines="none" detail={false}>
                  <IonIcon slot="start" ios={appPage.iosIcon} md={appPage.mdIcon} />
                  <IonLabel>{appPage.title}</IonLabel>
                </IonItem> : null}

              </IonMenuToggle>
            );
          })}
        </IonList>
        {/* <div>This is a partially working alpha version intended as proof of concept.</div> */}
        {/* <IonList id="labels-list">
          <IonListHeader>Switch Chains</IonListHeader>
          {labels.map((label, index) => (
            <IonItem lines="none" key={index}>
              <IonIcon slot="start" icon={bookmarkOutline} />
              <IonLabel>{label}</IonLabel>
            </IonItem>
          ))}
        </IonList> */}
      </IonContent>
    </IonMenu>
  );
};

export default Menu;


