import { IonButton, IonButtons, IonContent, IonHeader, IonItem, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { useHistory, useParams } from 'react-router';
// import './Template.css';

import { RootState } from '../store';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import tokenMetadata from '../abi/TokenMetadata.json';

//import {Viewer}  from 'tiny-viewer'


import axios from 'axios'
import { ethers } from 'ethers';
import Topbar from '../components/TopBar';
import BulkUpload from '../components/BulkUpload';
import { loading } from '../features/counter/counterSlice';
import { refresh } from '../features/counter/profileSlice';
import { add } from 'ionicons/icons';
const NftStore: React.FC = () => {
  const stoken = useSelector((state: RootState) => state.profile.token)
  const rpc = useSelector((state: RootState) => state.profile.rpc)
  const update = useSelector((state: RootState) => state.profile.refresh)
  const ipfsGateway = useSelector((state: RootState) => state.site.ipfsGateway)
  const pk = useSelector((state: RootState) => state.profile.pk)
  const profile = useSelector((state: RootState) => state.profile)
  //const site = useSelector((state: RootState) => state.site)
  const history = useHistory();

  const dispatch = useDispatch()

  const site = useSelector((state: RootState) => state.site)
  const provider = new ethers.providers.JsonRpcProvider(rpc);

  const { name } = useParams<{ name: string; }>();
  const [site_collections, setItems] = useState<any>([]);
  const [ownerAddress, setOwnerAddress]  = useState<string>("");
  const fetchIpfs = async (uri: string) => {
    const metadata = await fetch(uri.replace("ipfs://", ipfsGateway));//"https://cloudflare-ipfs.com/ipfs/"));
    let meta = await metadata.json()
    try {
      meta.url = meta.url.replace("ipfs://", ipfsGateway)//"https://cloudflare-ipfs.com/ipfs/")
      meta.animation_url = meta.animation_url.replace("ipfs://", ipfsGateway)
      meta.external_link = meta.external_link.replace("ipfs://", ipfsGateway)

    } catch (e) {
      console.log(e)
    }
    return meta;
  }
  const init = async () => {

    dispatch(loading(true))

    const siteCollection = new ethers.Contract(site.metadata.collection, tokenMetadata.abi, provider);
    console.log("site.metaContract.collection ", site.metadata.collection)
    //setItems((await siteCollection.tokenURI(0)).toString())

    const collections = []
    //console.log("All Collections ",  parseInt((await siteCollection.totalSupply())))
    const ownerAddress = await siteCollection.owner()
    console.log("ownerAddress ", ownerAddress)
    setOwnerAddress(ownerAddress)
    const ownerBallance = await siteCollection.balanceOf(ownerAddress)
    console.log(ownerBallance.toString())
    //for (let x = 0; x < parseInt((await siteCollection.totalSupply())); x++) {
    for (let x = 0; x < parseInt(ownerBallance); x++) {
      
      const tokenOfOwnerByIndex = await siteCollection.tokenOfOwnerByIndex(ownerAddress, x)
      const address = (await siteCollection.tokenURI(tokenOfOwnerByIndex)).toString().split("://")[1]
      const collectionItem = new ethers.Contract(address, tokenMetadata.abi, provider);
      const nfts:any = []
      console.log("address ", address)

      const ownerBallance = await collectionItem.balanceOf(ownerAddress)
      try {
        const tokensInCollectionCount = parseInt((await collectionItem.tokenOfOwnerByIndex(ownerAddress, x)));
        console.log("tokensInCollectionCount ", tokensInCollectionCount)
      }catch(e){
        console.log(e)
      }
     
      collections.push({ collectoin: address, tokenId: tokenOfOwnerByIndex.toString(), items: nfts })
      for (let y = 0; y < ownerBallance; y++) {
        try {
          const tokenOfOwnerByIndex = await collectionItem.tokenOfOwnerByIndex(ownerAddress, y)
          const nftTokenMetadata = await fetchIpfs((await collectionItem.tokenURI(tokenOfOwnerByIndex)).toString())
          nftTokenMetadata.tokenId = tokenOfOwnerByIndex.toString();
          nftTokenMetadata.address = address;
          nfts.push(nftTokenMetadata)
          //setItems(Object.assign([], { collectoin: address, tokenId: tokenOfOwnerByIndex.toString(), items: nfts }))
        } catch (e) {
          console.log(e)
        }

        setItems(Object.assign([], collections))

      }
      
    

    }
    setItems(Object.assign([], collections))
    dispatch(loading(false))

  }
  useEffect(() => {
    init()
  }, [update])
  //s("test")
  const removeCollection = async (address: string, tokenId: string) => {
    dispatch(loading(true))
    const provider = new ethers.providers.JsonRpcBatchProvider(rpc);
    const signer = new ethers.Wallet(pk, provider)
    const collection = new ethers.Contract(address, tokenMetadata.abi, signer);
    const ownerAddress = await collection.owner()
    const ownerBallance = await collection.balanceOf(ownerAddress)

    // const tokensInCollectionCount = parseInt((await collection.tokenOfOwnerByIndex(ownerAddress, tokenId)));
    //console.log(tokensInCollectionCount)
try{


    for (let y = 0; y < ownerBallance; y++) {
      const tokenOfOwnerByIndex = await collection.tokenOfOwnerByIndex(ownerAddress, y)
      console.log(tokenOfOwnerByIndex)
      const result = await collection.burn(tokenOfOwnerByIndex.toString(),{nonce:0})

      // nfts.push(await fetchIpfs((await collectionItem.tokenURI(tokenOfOwnerByIndex)).toString()))
      // setItems(Object.assign([], collections))
    }
  }catch(e){
    console.log(e)
    console.log("retry")
    //removeCollection(address,tokenId)
  }
    // for (let x = 0; x < parseInt((await collection.totalSupply())); x++) {
    //   const result = await collection.burn(x.toString())
    //   console.log(result)
    // }
    const siteCollection = new ethers.Contract(site.metadata.collection, tokenMetadata.abi, signer);
    console.log("remove ", tokenId.toString())
    const result = await siteCollection.burn(tokenId.toString())
    console.log("site result ", result)
    dispatch(loading(false))
    dispatch(refresh())
  }
  const buyNftPage = (i: any) => {
    //alert(i.tokenId)
    history.push("/page/nftstore/" + i.address + "/" + i.tokenId)
  }
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
        <Topbar />
          {/* <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{name}</IonTitle> */}
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{name}</IonTitle>
          </IonToolbar>
        </IonHeader>
        {!site.progressBar && site_collections.length ===0?<div className='container'>
        <BulkUpload mode={"nft"}/>
        </div>:null}
        {site_collections.map((coll: any, j: any) => {

          return (
            <div key={j}>
              <h4> {profile.pubk===ownerAddress?
              <IonButton onClick={() => { removeCollection(coll.collectoin, coll.tokenId) }}>Remove</IonButton>:
              null}{coll.collectoin}</h4>
              {/* { profile.profile.email.indexOf("@xsolla.com")>0? */}
             
              <div className='flex-container-nft'>

                <br />
                {

                  coll.items.map((i: any, k: any) => {
                    //return <div key={k}>hi</div>
                    return (
                      // <>
                      <div className='card' key={k} onClick={() => buyNftPage(i)}>
                        <img className='nft-img' crossOrigin='anonymous' alt={i.name} src={i.url} />
                        <br />
                        {i.name}
                        <br />
                        {/* {i.token_id} */}
                        {/* {i.group.name} #{i.token_id} */}
                        {/* <IonItem>
                  <IonIcon icon={flame} onClick={() => { burn(i, k) }} />
                  <IonIcon icon={send} onClick={() => { sendNft(i, k) }} slot="end" />
                </IonItem> */}
                      </div>

                    )
                  })
                }
                <hr />
              </div>
            </div>
          )
          // })
        })}

        <pre>
          {/* {JSON.stringify(site_collections, null, 2)} */}
        </pre>
      </IonContent>
    </IonPage>
  );
};
export default NftStore;


