import { IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonContent, IonHeader, IonIcon, IonItem, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { useParams } from 'react-router';
import ExploreContainer from '../components/ExploreContainer';
import './Cash.css';

import { RootState } from '../store';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
// import {C  } from '@web3uikit/core';
// import { ConnectButton } from "web3uikit";
import { WalletModal } from '@web3uikit/web3';
import axios from 'axios'

import Arweave from 'arweave';
import { entropyToMnemonic, mnemonicToSeed } from 'bip39-web-crypto'
// @ts-ignore
import { generateKeyPair, getKeyPairFromMnemonic, getKeyPairFromSeed } from 'human-crypto-keys';
import { pk, pubk, refresh } from '../features/counter/profileSlice';

import Chainlet from './Chainlet'

import * as zksync from "zksync-web3";
import * as ethers from "ethers";
import faucet from "../abi/faucet.json"
import { Button } from '@web3uikit/core';
import { sendOutline, swapHorizontal, swapHorizontalOutline, swapHorizontalSharp } from 'ionicons/icons';
import Topbar from '../components/TopBar';
//import { token, admin, pk, pubk } from '../features/counter/profileSlice'

//import { defaultPath, HDNode, entropyToMnemonic, Mnemonic } from "@ethersproject/hdnode";

import { Connection, Keypair, LAMPORTS_PER_SOL, PublicKey, SystemProgram, Transaction } from "@solana/web3.js";
import * as bs58 from "bs58";
import * as solanaWeb3 from "@solana/web3.js";
import {
  sendAndConfirmTransaction,
  clusterApiUrl,
  // Connection,
} from "@solana/web3.js";
import truncateEthAddress from 'truncate-eth-address'
import { CopyButton, useNotification } from '@web3uikit/core'
import { loading } from '../features/counter/counterSlice';

const Cash: React.FC = () => {

  // connection



  const stoken = useSelector((state: RootState) => state.profile.token)
  const { name } = useParams<{ name: string; }>();
  const [items, setItems] = useState([]);
  const [coninBalance, setConinBalance] = useState("");
  const [usdcBalance, setUSDCBalance] = useState("");
  const [xlaBalance, setXlaBalance] = useState("Loading");
  const [ethBalance, setEthBalance] = useState("");
  const [solBalance, setSolBalance] = useState("");
  const [arBalance, setArBalance] = useState("0");
  const [arAddress, setArAddress] = useState("0");
  const [solAddress, setSolAddress] = useState("0");

  const profie = useSelector((state: RootState) => state.profile)

  const dispatch = useDispatch()

   const arweave = Arweave.init({
    host: 'arweave.net',
    port: 443,
    protocol: 'https'
  });

  const syncProvider = new zksync.Provider("https://zksync2-testnet.zksync.dev");
  const ethProvider = ethers.getDefaultProvider("goerli");
  // const PK = ethers.Wallet.fromMnemonic("advice dismiss project upset amateur invest embrace festival genuine off swear fruit")



  const clickDeposit = async () => {
    const syncProvider = new zksync.Provider("https://zksync2-testnet.zksync.dev");
    const ethProvider = ethers.getDefaultProvider("goerli");
    const syncWallet = new zksync.Wallet(pk, syncProvider, ethProvider);
    // console.log(syncWallet)
    // return;
    const deposit = await syncWallet.deposit({
      token: zksync.utils.ETH_ADDRESS,
      amount: ethers.utils.parseEther("0.001"),
    });
    console.log("deposit", deposit)
  }
  const sendZkUSDC = async () => {

    const syncWallet = new zksync.Wallet(pk, syncProvider, ethProvider);
    const amount = ethers.utils.parseUnits("1", 6);
    let addrs = window.prompt("Destination Address")
    if (addrs) {
      const t = await syncWallet.transfer({
        to: addrs,
        //token: zksync.utils.ETH_ADDRESS,
        token: "0x852a4599217e76aa725f0ada8bf832a1f57a8a91",
        amount,
      });
      console.log("send", t)
      dispatch(refresh())
    }
    //let r = await syncWallet.getBalance("0xc8acb475bb76a4b8ee36ea4d0e516a755a17fad2e84427d5559b37b544d9ba5a")
    //console.log(r.toString());
  }
  const sendZkEth = async () => {

    const syncWallet = new zksync.Wallet(pk, syncProvider, ethProvider);
    const amount = ethers.utils.parseUnits("1", 6);
    let addrs = window.prompt("Destination Address")
    if (addrs) {
      const t = await syncWallet.transfer({
        to: addrs,
        token: zksync.utils.ETH_ADDRESS,
        //token: "0x852a4599217e76aa725f0ada8bf832a1f57a8a91",
        amount,
      });
      console.log("send", t)
    }
    //let r = await syncWallet.getBalance("0xc8acb475bb76a4b8ee36ea4d0e516a755a17fad2e84427d5559b37b544d9ba5a")
    //console.log(r.toString());
  }
  const pk = useSelector((state: RootState) => state.profile.pk)
  const pubk = useSelector((state: RootState) => state.profile.pubk)
  const rpc = useSelector((state: RootState) => state.profile.rpc)
  //const gas = useSelector((state: RootState) => state.profile.gas)
  const update = useSelector((state: RootState) => state.profile.refresh)

  const pkcs8ToJwk = async (key: Uint8Array) => {
    const imported = await window.crypto.subtle.importKey('pkcs8', key, { name: 'RSA-PSS', hash: 'SHA-256' }, true, ['sign'])
    const jwk = await window.crypto.subtle.exportKey('jwk', imported)
    delete jwk.key_ops
    delete jwk.alg
    return jwk
  }
  useEffect(() => {



    const syncProvider = new zksync.Provider("https://zksync2-testnet.zksync.dev");
    const ethProvider = ethers.getDefaultProvider("goerli");
    //const PK = ethers.Wallet.fromMnemonic("advice dismiss project upset amateur invest embrace festival genuine off swear fruit")

    (async () => {
      dispatch(loading(true))

      const connection = new Connection("https://api.testnet.solana.com");

      // 5YNmS1R9nNSCDzb5a7mMJ1dwK9uHeAAF4CmPEwKgVWr8
      // let feePayer = Keypair.fromSecretKey(
      //   bs58.decode("588FU4PktJWfGfxtzpAAXywSNt74AvtroVzGfKkVN1LwRuvHwKGr851uH8czM5qm4iqLbs1kKoMKtMJG4ATR7Ld2")
      // );

      const mnemonic = profie.mnemonic //"pill tomorrow foster begin walnut borrow virtual kick shift mutual shoe scatter";


      const seed = await mnemonicToSeed(mnemonic, ""); // (mnemonic, password)
      const keypair = Keypair.fromSeed(seed.slice(0, 32));
      let feePayer = keypair;//Keypair.;


      //2QSFnjazjWLYjhtPw7cLUsMyyVoPAayg8sq4rgiZYJq9
      let balance = await connection.getBalance(feePayer.publicKey);
      console.log(feePayer.publicKey.toString())
      setSolAddress(feePayer.publicKey.toString())

      console.log(`${balance} SOL in `);// / LAMPORTS_PER_SOL
      setSolBalance((balance / LAMPORTS_PER_SOL).toString())

      // let alice:PublicKey = new PublicKey("2QSFnjazjWLYjhtPw7cLUsMyyVoPAayg8sq4rgiZYJq9")

      // let tx = new Transaction().add(
      //   SystemProgram.transfer({
      //     fromPubkey: feePayer.publicKey,//alice.publicKey,
      //     toPubkey: alice,//"4MWwxzWsWmHrsbfPFwE6LDq471nqNeNMsD6DS7y8nruw"),
      //     lamports: 1 * LAMPORTS_PER_SOL,
      //   })
      // );
      // tx.feePayer = feePayer.publicKey;



      // let txhash = await connection.sendTransaction(tx, [feePayer]);
      // console.log(`txhash: ${txhash}`);

      dispatch(loading(false))

    })();

    const syncWallet = new zksync.Wallet(pk, syncProvider, ethProvider);
    const getBalance = async () => {
      const committedEthBalance = await syncWallet.getBalance(zksync.utils.ETH_ADDRESS);
      setConinBalance(ethers.utils.formatEther(committedEthBalance.toString()).toString())
      setUSDCBalance(ethers.utils.formatUnits(await syncWallet.getBalance(pubk), "mwei").toString())
      //console.log("get balance")


      setEthBalance(ethers.utils.formatUnits((await ethProvider.getBalance(pubk)).toString(), "ether").toString())

      // setUSDCBalance("323")
      // console.log("address", await PK.getAddress())

      const provider = new ethers.providers.JsonRpcProvider(rpc);
      // let utf8Encode = new TextEncoder();
      // utf8Encode.encode("abc");
      // console.log("mnemonic",entropyToMnemonic([ 97, 98, 99 ,97]))

      //console.log("entropyToMnemonic ",await entropyToMnemonic(pk))
      const signer = new ethers.Wallet(pk, provider)
      let xlabal = await signer.getBalance()
      console.log("xlabal", xlabal.toString())
      let formatedAmount = parseFloat(ethers.utils.formatEther(xlabal.toString()).toString()).toFixed(3)
      setXlaBalance(formatedAmount)
      let abi: any = faucet.output.abi;
      // const faucetcontract = new ethers.Contract("0x769F13b4e9E6Ad735C9d2c226540eF167842AD8D", abi, signer);
      // await faucetcontract.requestTokens(pubk, { value: 0, gas: 200000 })
      // let xlabal2 = await signer.getBalance()
      // console.log("xlabal", xlabal2.toString())
      //0xd9145CCE52D386f254917e481eB44e9943F39138 faucet
      // arweave.wallets.ownerToAddress()
      /* @todo  */
      //let keyPair: any = await getKeyPairFromMnemonic(await entropyToMnemonic(pk), { id: 'rsa', modulusLength: 4096 }, { privateKeyFormat: 'pkcs8-der' })
      let keyPair: any = await getKeyPairFromMnemonic(profie.mnemonic, { id: 'rsa', modulusLength: 4096 }, { privateKeyFormat: 'pkcs8-der' })
      console.log("keyPair ", keyPair)
      let jwk: any = await pkcs8ToJwk(keyPair.privateKey)
      console.log("pkcs8ToJwk ", jwk)
      console.log("jwkToAddress", await arweave.wallets.jwkToAddress(jwk))
      let address =  await arweave.wallets.jwkToAddress(jwk);//await arweave.wallets.getAddress(jwk)
      setArAddress(address)
      console.log("arweave.getAddress", address)
      const arbal =  await arweave.wallets.getBalance(address)
      console.log("arweave.getBalance", arbal)
      let b = parseFloat(ethers.utils.formatUnits(await arweave.wallets.getBalance(address), 11)).toString()
      
      //consolelog()
      setArBalance(b)
      
     
      //   arweave.wallets.getBalance(pubk).then((balance) => {
      //     let winston = balance;
      //     let ar = arweave.ar.winstonToAr(balance);
      //     console.log(winston);
      //     //125213858712
      //     console.log(ar);
      //     //0.125213858712
      // });



    }
    getBalance();


  }, [update])
  const bridge = async (token: string) => {
    console.log(token)

  }
  const sendSol = async () => {
    const connection = new Connection("https://api.testnet.solana.com");

    // 5YNmS1R9nNSCDzb5a7mMJ1dwK9uHeAAF4CmPEwKgVWr8
    const feePayer = Keypair.fromSecretKey(
      bs58.decode("588FU4PktJWfGfxtzpAAXywSNt74AvtroVzGfKkVN1LwRuvHwKGr851uH8czM5qm4iqLbs1kKoMKtMJG4ATR7Ld2")
    );
    //2QSFnjazjWLYjhtPw7cLUsMyyVoPAayg8sq4rgiZYJq9
    let balance = await connection.getBalance(feePayer.publicKey);
    console.log(`${balance / LAMPORTS_PER_SOL} SOL`);
    setSolBalance((balance / LAMPORTS_PER_SOL).toString())

    let alice: PublicKey = new PublicKey("2QSFnjazjWLYjhtPw7cLUsMyyVoPAayg8sq4rgiZYJq9")

    let tx = new Transaction().add(
      SystemProgram.transfer({
        fromPubkey: feePayer.publicKey,//alice.publicKey,
        toPubkey: alice,//"4MWwxzWsWmHrsbfPFwE6LDq471nqNeNMsD6DS7y8nruw"),
        lamports: 1 * LAMPORTS_PER_SOL,
      })
    );
    tx.feePayer = feePayer.publicKey;

    let txhash = sendAndConfirmTransaction(connection, tx, [feePayer]);



    //let txhash = await connection.sendTransaction(tx, [feePayer]);
    console.log(`txhash: ${txhash}`);
  }
  const sendWei = async () => {
    const provider = new ethers.providers.JsonRpcProvider(rpc);
    const signer = new ethers.Wallet(pk, provider)
    let addrs = window.prompt("Destination Address")
    if (addrs) {
      let r = await signer.sendTransaction({
        to: addrs,
        value: "44337303850148292"//ethers.utils.parseEther('.0001')//"562675505251638"//ethers.utils.parseEther('2', 'ether')
      })
      console.log(r)
      dispatch(refresh())
      //alert(JSON.stringify(r))
      // let xlabal = await signer.getBalance()
      // dispatch(gas(xlabal.toString()))
    }

  }
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <Topbar />
          <IonTitle>{name}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent >
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{name}</IonTitle>
          </IonToolbar>
        </IonHeader>
        {/* <h2>Testnet</h2> */}
        <div className='flex'>
         
          {/* <Chainlet rpc="https://rinkeby.arbitrum.io/rpc" /> */}



          {/* </div>

        <div className='flex'> */}
          {/* <IonCard color="light">
            <IonCardHeader>
              <IonCardTitle>
                <svg viewBox="0 0 56 28">
                  <text x="0" y="15">
                    {xlaBalance}
                  
                  </text>
                  
                </svg>
              </IonCardTitle>
              <IonCardSubtitle>XLA </IonCardSubtitle>
            </IonCardHeader>
            <IonCardContent>
              XLAOS
            </IonCardContent>
            <IonItem>
              <IonIcon onClick={sendWei} icon={sendOutline} />
              <IonIcon slot='end' icon={swapHorizontalSharp} />
            </IonItem> */}
            {/* <IonButton onClick={sendWei}>Send</IonButton>
            <IonButton>Bridge</IonButton> */}
            {/* <IonButton>Widthdraw</IonButton> */}
          {/* </IonCard> */}
          <Chainlet rpc="https://rpc.ankr.com/eth_goerli" />
          <Chainlet rpc="https://xla-1674265553-1.sagarpc.xyz" token="XLA" chain="XLAOS" />
          <Chainlet rpc="https://evm-t3.cronos.org" />
          <Chainlet rpc="https://bsc-testnet.public.blastapi.io" />
          <Chainlet rpc="https://rpc.ankr.com/polygon_mumbai" />
          <Chainlet rpc="https://rpc.ankr.com/avalanche_fuji" />
          <Chainlet rpc="https://goerli.optimism.io" />
          <Chainlet rpc="https://rpc.ankr.com/fantom_testnet" />
          <IonCard color="light">
            <IonCardHeader>
              <IonCardTitle>
                <svg viewBox="0 0 56 28">
                  {/* <g> */}
                  <text x="0" y="15" >{parseFloat(coninBalance).toFixed(3)}</text>
                  {/* </g> */}
                </svg>
              </IonCardTitle>
              <IonCardSubtitle>Eth</IonCardSubtitle>
            </IonCardHeader>

            <IonCardContent>
              ZkSync
            </IonCardContent>
            {/* <IonButton onClick={sendZkEth}>Send</IonButton>
            <IonButton>Bridge</IonButton> */}
            <IonItem>
              <IonIcon onClick={sendZkEth} icon={sendOutline} />
              <IonIcon slot='end' icon={swapHorizontalSharp} />
            </IonItem>
            {/* <IonButton>Payout</IonButton> */}
            {/* <IonButton>Widthdraw</IonButton> */}
          </IonCard>
          <IonCard color="light">
            <IonCardHeader>
              <IonCardTitle>
                <svg viewBox="0 0 56 28">
                  <text x="0" y="15">{parseFloat(usdcBalance).toFixed(3)}</text>
                </svg></IonCardTitle>
              <IonCardSubtitle>USDC </IonCardSubtitle>
            </IonCardHeader>
            <IonCardContent>
              ZkSync
            </IonCardContent>
            <IonItem>
              <IonIcon onClick={sendZkUSDC} icon={sendOutline} />
              <IonIcon slot='end' icon={swapHorizontalSharp} />
            </IonItem>
            {/* <IonButton onClick={sendZkUSDC}>Send</IonButton>
            <IonButton>Bridge</IonButton> */}
            {/* <IonButton>Widthdraw</IonButton> */}
          </IonCard>
          {/* <IonCard color="light">
            <IonCardHeader>
              <IonCardTitle>
                <svg viewBox="0 0 56 28">
                  <text x="0" y="15">{parseFloat(ethBalance.toString()).toFixed(3)}</text>
                </svg>
              </IonCardTitle>
              <IonCardSubtitle>Eth </IonCardSubtitle>
            </IonCardHeader>
            <IonCardContent>
              Ethereum

            </IonCardContent>
            <IonItem>
              <IonIcon onClick={sendZkUSDC} icon={sendOutline} />
              <IonIcon onClick={() => bridge("eth")} slot='end' icon={swapHorizontalSharp} />
            </IonItem> */}
            {/* <IonButton onClick={sendZkUSDC}>Send</IonButton> */}
            {/* <IonButton>Bridge</IonButton> */}

            {/* <IonButton>Widthdraw</IonButton> */}

          {/* </IonCard> */}
         


          <IonCard color="light">
            <IonCardHeader>
              <IonCardTitle>
                <svg viewBox="0 0 56 28">
                  <text x="0" y="15">{arBalance}</text>
                </svg> 
              </IonCardTitle>
              <IonCardSubtitle>Ar {arAddress.slice(0, 4) + "..."+arAddress.slice(arAddress.length-4)}<CopyButton text={arAddress} /></IonCardSubtitle>
            </IonCardHeader>
            <IonCardContent>
              ArWeave
            </IonCardContent>
            <IonItem>
              <IonIcon onClick={sendZkUSDC} icon={sendOutline} />
              <IonIcon onClick={() => bridge("eth")} slot='end' icon={swapHorizontalSharp} />
            </IonItem>
            {/* <IonButton onClick={sendZkUSDC}>Send</IonButton> */}
            {/* <IonButton>Bridge</IonButton> */}

            {/* <IonButton>Widthdraw</IonButton> */}

          </IonCard>
          <IonCard color="light">
            <IonCardHeader>
              <IonCardTitle>
                <svg viewBox="0 0 56 28">
                  <text x="0" y="15">{parseFloat(solBalance.toString()).toFixed(3)}</text>
                </svg>
              </IonCardTitle>
              <IonCardSubtitle>Sol {truncateEthAddress("0x"+solAddress).substring(2)}<CopyButton text={solAddress} /></IonCardSubtitle>
            </IonCardHeader>
            <IonCardContent>
              Solana

            </IonCardContent>
            <IonItem>
              <IonIcon onClick={sendSol} icon={sendOutline} />
              <IonIcon onClick={() => bridge("eth")} slot='end' icon={swapHorizontalSharp} />
            </IonItem>

            {/* <IonButton onClick={sendZkUSDC}>Send</IonButton> */}
            {/* <IonButton>Bridge</IonButton> */}

            {/* <IonButton>Widthdraw</IonButton> */}

          </IonCard>



        </div>



        {/* <ConnectButton /> */}
        {/* <WalletModal
           isOpened
           setIsOpened={function noRefCheck(){}}
 /> */}
        <button onClick={clickDeposit}>Deposit</button>
      {/* <button onClick={click}>Withdraw to Bank</button>
      <button onClick={send}>Send</button> */}
        <br />
        {/* ETH:{coninBalance} */}
        <br />
        {/* USDC: {usdcBalance} */}
      Active RPC: {rpc}
      </IonContent>
    </IonPage>
  );
};

export default Cash;


