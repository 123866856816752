import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { useParams } from 'react-router';
import ExploreContainer from '../components/ExploreContainer';
import './Deploy.css';

import { RootState } from '../store';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import axios from 'axios'
import { refresh } from 'ionicons/icons';
import { useDispatch } from 'react-redux';
import { pk, pubk, refresh as refreshScreen } from '../features/counter/profileSlice';
import Topbar from '../components/TopBar';

const Deploy: React.FC = () => {
  const stoken = useSelector((state: RootState) => state.profile.token)
  const { name } = useParams<{ name: string; }>();
  const [items, setItems] = useState([]);
  const history = useHistory();
  const buy = async (i: any) => {
    if (!window.confirm("Buy")) {
      return;
    }
    //throw new Error('Function not implemented.');
    const query = new URLSearchParams({
      platform: 'playstation_network'
    }).toString();
    console.log(i)
    const projectId = '205984';
    const itemSku = i.sku;
    const virtualCurrencySku = 'YOUR_virtual_currency_sku_PARAMETER';
    const resp = await fetch(
      `https://store.xsolla.com/api/v2/project/${projectId}/free/item/${itemSku}`,///virtual/${virtualCurrencySku}?${query}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + stoken
        },
        body: JSON.stringify({
          custom_parameters: {
            array_param: [
              {
                object1: {
                  param: 'https://xsolla.com'
                },
                property: {
                  'int-property': 112,
                  'string-property': 'xsolla'
                }
              },
              {
                object2: {
                  param: 'https://developers.xsolla.com'
                },
                property: {
                  'int-property': 112,
                  'string-property': 'email@example.com'
                }
              }
            ],
            object_param: { key: 'value' },
            bool_param: true,
            int_param: 100,
            null_param: null
          }
        })
      }
    );

    const data = await resp.json();
    console.log(data);
  }
  useEffect(() => {
    //https://axios-http.com/docs/example
    //https://medium.com/swlh/interacting-with-restful-apis-using-typescript-react-hooks-and-axios-part-1-af52920ae3e4
    //let axios = new Axios()
    //axios.get('https://store.xsolla.com/api/v2/project/44056/user/inventory/items',
    //205984
    //axios.get('https://store.xsolla.com/api/v2/project/2059842/user/inventory/items',
    //axios.get('https://store.xsolla.com/api/v2/project/88974/user/inventory/items',
    axios.get('https://store.xsolla.com/api/v2/project/'+window.project_id+'/items/virtual_items',
      {
        headers: {
          "authorization": "Bearer " + stoken //"eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE5NjIyMzQwNDgsImlzcyI6Imh0dHBzOi8vbG9naW4ueHNvbGxhLmNvbSIsImlhdCI6MTU2MjE0NzY0OCwidXNlcm5hbWUiOiJ4c29sbGEiLCJ4c29sbGFfbG9naW5fYWNjZXNzX2tleSI6IjA2SWF2ZHpDeEVHbm5aMTlpLUc5TmMxVWFfTWFZOXhTR3ZEVEY4OFE3RnMiLCJzdWIiOiJkMzQyZGFkMi05ZDU5LTExZTktYTM4NC00MjAxMGFhODAwM2YiLCJlbWFpbCI6InN1cHBvcnRAeHNvbGxhLmNvbSIsInR5cGUiOiJ4c29sbGFfbG9naW4iLCJ4c29sbGFfbG9naW5fcHJvamVjdF9pZCI6ImU2ZGZhYWM2LTc4YTgtMTFlOS05MjQ0LTQyMDEwYWE4MDAwNCIsInB1Ymxpc2hlcl9pZCI6MTU5MjR9.GCrW42OguZbLZTaoixCZgAeNLGH2xCeJHxl8u8Xn2aI"
        }
      }
    )
      .then(function (response) {
        // handle success
        console.log(response);
        setItems(response.data.items)
        // setState
      })

    return;
    var data = null;

    var xhr = new XMLHttpRequest();
    xhr.withCredentials = true;

    xhr.addEventListener("readystatechange", function () {
      if (this.readyState === this.DONE) {
        console.log(this.responseText);
      }
    });

    xhr.open("GET", "https://store.xsolla.com/api/v2/project/44056/user/inventory/items");
    xhr.setRequestHeader("authorization", "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE5NjIyMzQwNDgsImlzcyI6Imh0dHBzOi8vbG9naW4ueHNvbGxhLmNvbSIsImlhdCI6MTU2MjE0NzY0OCwidXNlcm5hbWUiOiJ4c29sbGEiLCJ4c29sbGFfbG9naW5fYWNjZXNzX2tleSI6IjA2SWF2ZHpDeEVHbm5aMTlpLUc5TmMxVWFfTWFZOXhTR3ZEVEY4OFE3RnMiLCJzdWIiOiJkMzQyZGFkMi05ZDU5LTExZTktYTM4NC00MjAxMGFhODAwM2YiLCJlbWFpbCI6InN1cHBvcnRAeHNvbGxhLmNvbSIsInR5cGUiOiJ4c29sbGFfbG9naW4iLCJ4c29sbGFfbG9naW5fcHJvamVjdF9pZCI6ImU2ZGZhYWM2LTc4YTgtMTFlOS05MjQ0LTQyMDEwYWE4MDAwNCIsInB1Ymxpc2hlcl9pZCI6MTU5MjR9.GCrW42OguZbLZTaoixCZgAeNLGH2xCeJHxl8u8Xn2aI");

    xhr.send(data);
  }, [])
  const dispatch = useDispatch()

  const deleteAll = () =>{
    //deleteItem
    items.map((i:any)=>{
      deleteItem(i.sku)
      return null
    })
    dispatch(refreshScreen())
  }
  const admin = useSelector((state: RootState) => state.profile.admin)

  async function deleteItem(itemSku:string) {
    const projectId = window.project_id;
   // const itemSku = 'YOUR_item_sku_PARAMETER';
    const resp = await fetch(
      `https://store.xsolla.com/api/v2/project/${projectId}/admin/items/virtual_items/sku/${itemSku}`,
      {
        method: 'DELETE',
        headers: {
          "authorization": "Basic " + btoa(admin.merchant_id + ":" + admin.api)        }
      }
    );
  
    if (resp.status === 204) {
      console.log('success');
    } else {
      const data = await resp.text();
      console.log(data);
    }
  }
  
  const deployItem = (sku: string) => {
    console.log(sku)
    history.push("/page/deploy/" + sku)
  }
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
        <Topbar/>
          {/* <IonTitle>License</IonTitle> */}
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">License</IonTitle>
          </IonToolbar>
        </IonHeader>
        {/* {stoken} */}
        {/* <br/>
        {items.length} */}
        
        <div className='flex-container-nft'>
          {items.map((i: any, k: any) => {
            return (<div  className='card' key={k}>
              <img className='nft-img' alt={i.name} src={i.image_url} />
              <br/>
              {i.name}
              <br />
              {i.token_id}
              <button onClick={() => deployItem(i.sku)}>Mint Licence</button>
            </div>)
            // return <img   key={x} width={200} height={200} alt={i.sku}onClick={()=>buy(i)} src={i.image_url}/> 
          })
          }
                 
as
        </div>
        {/* <button onClick={deleteAll}>Delete All</button> */}
        {/* <ExploreContainer name={name} /> */}
      </IonContent>
    </IonPage>
  );
};

export default Deploy;


