import { IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonContent, IonHeader, IonItem, IonLabel, IonList, IonMenuButton, IonPage, IonThumbnail, IonTitle, IonToolbar } from '@ionic/react';
import { useHistory, useParams } from 'react-router';
import ExploreContainer from '../components/ExploreContainer';
import './Page.css';

import { RootState } from '../store';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import axios from 'axios'
import { refresh } from '../features/counter/profileSlice';
import { loading } from '../features/counter/counterSlice';

import { ethers } from 'ethers';
import { useLocation } from 'react-router-dom';
import Topbar from '../components/TopBar';
import { beaker } from 'ionicons/icons';

//import embed from '../../bower_components/xsolla-paystation-widget/src/app'

// import fetch from 'node-fetch';
const Page: React.FC = () => {
  const stoken = useSelector((state: RootState) => state.profile.token)
  const site = useSelector((state: RootState) => state.site)
  const { name } = useParams<{ name: string; }>();
  const [items, setItems] = useState([]);
  const [subscriptionList, setSubscriptionList] = useState<any>([]);

  const [dropsHeader, setSetDropsHeader] = useState<any>([]);

  const history = useHistory();
  const dispatch = useDispatch()
  const pubk: any = useSelector((state: RootState) => state.profile.pubk)
  const [projects, setProjects] = useState<any>([]);

  const mainDomain = () => {
    if (window.location.host.indexOf("localhost") === 0) {
      return window.location.host.split(".").length > 0
    } else {
      return window.location.host.split(".").length === 2
    }
  }
  const visit = (i: any) => {
    //history.push(i.name)
    window.location.assign(window.location.protocol + "//metamall-" + i.id + "-" + i.name.replaceAll(" ", "-") + "." + window.location.host)
    //  event.preventDefault();
    history.block()
  }
  const buy = async (i: any) => {
    if (mainDomain()) {
      console.log(i)
      window.location.assign(window.location.protocol + "//" + i.sku + "." + window.location.host)
      return;
    }
    if (!pubk.length) {
      history.push("/auth/login")
      return;
    }
    if (!window.confirm("Buy")) {
      return;
    }
    //throw new Error('Function not implemented.');
    const query = new URLSearchParams({
      platform: 'playstation_network'
    }).toString();
    console.log(i)
    const projectId = window.project_id;
    const itemSku = i.sku;
    const virtualCurrencySku = 'YOUR_virtual_currency_sku_PARAMETER';
    const resp = await fetch(
      `https://store.xsolla.com/api/v2/project/${projectId}/free/item/${itemSku}`,///virtual/${virtualCurrencySku}?${query}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + stoken
        },
        body: JSON.stringify({
          custom_parameters: {
            array_param: [
              {
                object1: {
                  param: 'https://xsolla.com'
                },
                property: {
                  'int-property': 112,
                  'string-property': 'xsolla'
                }
              },
              {
                object2: {
                  param: 'https://developers.xsolla.com'
                },
                property: {
                  'int-property': 112,
                  'string-property': 'email@example.com'
                }
              }
            ],
            object_param: { key: 'value' },
            bool_param: true,
            int_param: 100,
            null_param: null
          }
        })
      }
    );

    const data = await resp.json();
    setTimeout(() => dispatch(refresh()), 1000);
    history.push("/page/items")
    console.log(data);
  }
  const getGroups = async () => {
    //const projectId = '';

    if (window.project_id === undefined || window.project_id==="") {
      return [];
    }
    try {


      const resp = await fetch(
        `https://store.xsolla.com/api/v2/project/${window.project_id}/items/groups`,
        { method: 'GET' }
      );

      const data = await resp.json();
      console.log(data);
      return data.groups
    } catch (e) {
      console.log(e);
      return [];
    }
  }
  const getGroupItems = async (externalId: string) => {
    const query = new URLSearchParams({
      limit: '50',
      offset: '0',
      locale: 'en',
      'additional_fields[]': 'string',
      country: 'US'
    }).toString();

    const projectId = window.project_id;
    //const externalId = 'YOUR_external_id_PARAMETER';
    const resp = await fetch(
      `https://store.xsolla.com/api/v2/project/${projectId}/items/virtual_items/group/${externalId}?${query}`,
      { method: 'GET' }
    );

    const data = await resp.json();
    console.log(data)
    return data
    console.log(data);
  }


  const update = useSelector((state: RootState) => state.profile.refresh)
  const conractData: any = useSelector((state: RootState) => state.profile.contractData)
  // const conractData: any = useSelector((state: RootState) => state.profile.contractData)

  const dropsData: any = useSelector((state: RootState) => state.profile.dropsData)
  const query = new URLSearchParams(useLocation().search);
  const getItem = async function () {
    // const query = new URLSearchParams({
    //   limit: '1',
    //   offset: '0',
    //   locale: 'en',
    //   'additional_fields[]': 'string',
    //   country: 'US'
    //  // item_sku:"drop-210861-mayweather"
    // }).toString();

    const projectId = window.top_project_id;
    const resp = await fetch(
      `https://store.xsolla.com/api/v2/project/${projectId}/items/virtual_items/sku/` + window.location.host.split(".")[0],//drop-210861-mayweather?${query}`,
      {
        method: 'GET',
        headers: {
          // Authorization: 'Bearer <YOUR_JWT_HERE>'
          Authorization: 'Bearer ' + stoken
        }
      }
    );
    const data: any = await resp.json();
    const arr: any = []
    arr.push(data)
    setSetDropsHeader(arr)
  }
  const subscriptionListGet = async () => {
    if(stoken===""){
      console.log("Token not set")
      return;
    }
    // 'https://subscriptions.xsolla.com​/api/user/v1/projects/{projectId}/subscriptions?locale=ru&limit=5&offset=5 ' \
    // -H 'accept: application/json' \
    // -H 'Authorization: Bearer client_user_jwt'
    const projectId = window.top_project_id;
    const resp = await axios.get(
      `https://subscriptions.xsolla.com​/api/user/v1/projects/${projectId}/subscriptions?plan_external_id=32`,//locale=ru&limit=5&offset=5
      {
        method: 'GET',
        headers: {
          // Authorization: 'Bearer <YOUR_JWT_HERE>'
          Authorization: 'Bearer ' + stoken
        }
      }
    );
    const data: any = resp;//await resp.json();
    console.log("subs", data)
    setSubscriptionList(data.items)
  }
  useEffect(() => {
    subscriptionListGet()
    //https://subscriptions.xsolla.com​/api/user/v1/projects/{project_id}/subscriptions/manage


    

    if (window.location.href.indexOf("result") > -1) {
      setTimeout(() => history.replace("/"), 0)
    }
    (async () => {
      dispatch(loading(true))

      getItem()
      try {

      } catch (e) {
        console.log(e)
      }

      if (window.location.hostname.startsWith("metamall") || mainDomain()) {

        if (mainDomain()) {

          let metamall = await fetch("https://megamall-api.x.la/v1/projects?limit=12&tags=3")
          setProjects((await metamall.json()).projects);//.filter((p:any  ) => p.url !=null))
          //    console.log(await metamall.json())


        }
        let context = window.location.host.split(".")[0].split("-")
        console.log(context)
        switch (context[0]) {
          case "metamall":
            let metamall = await fetch("https://megamall-api.x.la/v1/projects?id=" + context[1])
            setProjects((await metamall.json()).projects)
            break;
        }

      }
      console.log(update)

      // if(query.get("site")){
      //   window.location.replace(query.get("site") + "")
      //  console.log("site ", query.get("site"))
      // }
      if (dropsData.length > 0 || update === 0 || window.project_id === "") {
        //return;
      }
      if (true || mainDomain()) {
        // (async () => {
        if (mainDomain()) {
          window.project_id = "205984"
        } else {
          if (window.location.hostname.startsWith("drop")) {
            window.project_id = window.location.hostname.split("-")[1]
          }
          if (site.metadata != null) {
            window.project_id = site.metadata.project_id;
          }


        }
        //window.project_id = "212029"
        if (window.project_id === undefined) {
          // return;
        }
        let allItems: any = []
        let groups: any = (await getGroups());//.reverse()
        for (let x: number = 0; x < groups.length; x++) {
          console.log(groups[x].external_id)
          allItems.push({ name: groups[x].name, items: await getGroupItems(groups[x].external_id) })
        }
        console.log("allItems ", allItems)
        setItems(allItems)
        // })();
      }
      console.log(window.location.href.indexOf("result"))

      dispatch(loading(false))
    })()

    return;
    //https://axios-http.com/docs/example
    //https://medium.com/swlh/interacting-with-restful-apis-using-typescript-react-hooks-and-axios-part-1-af52920ae3e4
    //let axios = new Axios()
    //axios.get('https://store.xsolla.com/api/v2/project/44056/user/inventory/items',
    //205984
    //axios.get('https://store.xsolla.com/api/v2/project/2059842/user/inventory/items',
    //axios.get('https://store.xsolla.com/api/v2/project/88974/user/inventory/items',
    axios.get('https://store.xsolla.com/api/v2/project/' + window.project_id + '/items/virtual_items',
      {
        headers: {
          "authorization": "Bearer " + stoken //"eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE5NjIyMzQwNDgsImlzcyI6Imh0dHBzOi8vbG9naW4ueHNvbGxhLmNvbSIsImlhdCI6MTU2MjE0NzY0OCwidXNlcm5hbWUiOiJ4c29sbGEiLCJ4c29sbGFfbG9naW5fYWNjZXNzX2tleSI6IjA2SWF2ZHpDeEVHbm5aMTlpLUc5TmMxVWFfTWFZOXhTR3ZEVEY4OFE3RnMiLCJzdWIiOiJkMzQyZGFkMi05ZDU5LTExZTktYTM4NC00MjAxMGFhODAwM2YiLCJlbWFpbCI6InN1cHBvcnRAeHNvbGxhLmNvbSIsInR5cGUiOiJ4c29sbGFfbG9naW4iLCJ4c29sbGFfbG9naW5fcHJvamVjdF9pZCI6ImU2ZGZhYWM2LTc4YTgtMTFlOS05MjQ0LTQyMDEwYWE4MDAwNCIsInB1Ymxpc2hlcl9pZCI6MTU5MjR9.GCrW42OguZbLZTaoixCZgAeNLGH2xCeJHxl8u8Xn2aI"
        }
      }
    )
      .then(function (response) {
        // handle success
        console.log(response);
        setItems(response.data.items)
        // setState
      })

    return;
    var data = null;

    var xhr = new XMLHttpRequest();
    xhr.withCredentials = true;

    xhr.addEventListener("readystatechange", function () {
      if (this.readyState === this.DONE) {
        console.log(this.responseText);
      }
    });

    xhr.open("GET", "https://store.xsolla.com/api/v2/project/44056/user/inventory/items");
    xhr.setRequestHeader("authorization", "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE5NjIyMzQwNDgsImlzcyI6Imh0dHBzOi8vbG9naW4ueHNvbGxhLmNvbSIsImlhdCI6MTU2MjE0NzY0OCwidXNlcm5hbWUiOiJ4c29sbGEiLCJ4c29sbGFfbG9naW5fYWNjZXNzX2tleSI6IjA2SWF2ZHpDeEVHbm5aMTlpLUc5TmMxVWFfTWFZOXhTR3ZEVEY4OFE3RnMiLCJzdWIiOiJkMzQyZGFkMi05ZDU5LTExZTktYTM4NC00MjAxMGFhODAwM2YiLCJlbWFpbCI6InN1cHBvcnRAeHNvbGxhLmNvbSIsInR5cGUiOiJ4c29sbGFfbG9naW4iLCJ4c29sbGFfbG9naW5fcHJvamVjdF9pZCI6ImU2ZGZhYWM2LTc4YTgtMTFlOS05MjQ0LTQyMDEwYWE4MDAwNCIsInB1Ymxpc2hlcl9pZCI6MTU5MjR9.GCrW42OguZbLZTaoixCZgAeNLGH2xCeJHxl8u8Xn2aI");

    xhr.send(data);
  }, [update])
  const mintItem = async (sku: string) => {
  }
  const subscribe = () => {
    //var XPayStationWidget = embed;//require('../../bower//embed');

    // import fetch from 'node-fetch';

    const run = async () => {
      console.log("stoken ", stoken)
      const projectId = "205984";//window.project_id;
      const resp = await fetch(
        //`https://store.xsolla.com/api/v2/project/${projectId}/payment/cart`,
        `https://subscriptions.xsolla.com​/api/user/v1/projects/${projectId}/subscriptions/buy`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + stoken
          },
          body: JSON.stringify({
            "plan_external_id": "JaBEeBG4",
          })
        }
      );
      const data = await resp.json();
      //window.open(data.link_to_ps)
      //window.open(data.link_to_ps.replace("secure","demo-secure"))
      var options = {

        access_token: data.link_to_ps.split("=")[1], // "eop1KPrXqBsMgGP4xNgbM6espu8g9eAb_lc_en",
        //host: 'secure.xsolla.com',
        //host: 'sandbox-secure.xsolla.com',
        // sandbox: true, 
        lightbox: {
          width: '740px',
          height: '760px',
          spinner: 'round',
          spinnerColor: '#cccccc'
        }
      };
      window.XPayStationWidget.init(options);
      // window.XPayStationWidget.init({
      //    access_token: 'eop1KPrXqBsMgGP4xNgbM6espu8g9eAb_lc_en'
      // });
      window.XPayStationWidget.open()
      console.log(data);
    }
    run()
  }
  const cancel = async () => {
    //var XPayStationWidget = embed;//require('../../bower//embed');

    // import fetch from 'node-fetch';
    console.log("stoken ", stoken)
    const projectId = "205984";//window.project_id;
    // alert();
    const resp = await fetch(

      "https://subscriptions.xsolla.com/api/user/v1/projects/205984/subscriptions/78281799/cancel",
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + stoken
        }
      }
    );
    const data = await resp.json();





  }
  const renew = async () => {
    //var XPayStationWidget = embed;//require('../../bower//embed');

    // import fetch from 'node-fetch';
    console.log("stoken ", stoken)
    const projectId = "205984";//window.project_id;
    // alert();
    const resp = await fetch(
      //`https://store.xsolla.com/api/v2/project/${projectId}/payment/cart`,
      //`https://subscriptions.xsolla.com​/api/user/v1/projects/${projectId}/subscriptions/manage`,
      "https://subscriptions.xsolla.com/api/user/v1/projects/205984/subscriptions/78281799/renew",
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + stoken
        },
        body: JSON.stringify({
          "plan_external_id": "JaBEeBG4",
        })
      }
    );
    const data = await resp.json();
    //window.open(data.link_to_ps)
    //window.open(data.link_to_ps.replace("secure","demo-secure"))
    var options = {

      access_token: data.link_to_ps.split("=")[1], // "eop1KPrXqBsMgGP4xNgbM6espu8g9eAb_lc_en",
      //host: 'secure.xsolla.com',
      //host: 'sandbox-secure.xsolla.com',
      // sandbox: true, 
      lightbox: {
        width: '740px',
        height: '760px',
        spinner: 'round',
        spinnerColor: '#cccccc'
      }
    };
    window.XPayStationWidget.init(options);
    // window.XPayStationWidget.init({
    //    access_token: 'eop1KPrXqBsMgGP4xNgbM6espu8g9eAb_lc_en'
    // });
    window.XPayStationWidget.open()
    console.log(data);




  }
  const listPlans = async () => {
    console.log("stoken ", stoken)
    const projectId = "205984";//window.project_id;
    const resp = await fetch(
      //`https://store.xsolla.com/api/v2/project/${projectId}/payment/cart`,
      `https://subscriptions.xsolla.com​/api/user/v1/projects/${projectId}/plans?`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + stoken
        },
      }
    );
    const data = await resp.json();
    //window.open(data.link_to_ps)
    console.log(data);
  }
  const renderItems = (x: any) => {
    return x.map((i: any, k: any) => {
      // g.map((i:any,j:any)=>{
      return (
        <div className='card' key={k}>
          <img className='nft-img' alt={i.name} src={i.image_url.replace("ipfs.io", "cloudflare-ipfs.com")} />
          <br />
          {i.name}
          <br />
          {i.token_id}
          <br />
          <div dangerouslySetInnerHTML={{ __html: i.description.replace("{token}", stoken).replace("{sku}", i.sku) }} />
          {mainDomain() ?
            <button onClick={() => window.location.assign(window.location.protocol + "//" + i.sku + "." + window.location.host)}
            >Explore</button> :
            subscriptionList?.length ?
              <button onClick={() => buy(i)}>Subscribe</button> ://<button onClick={() => buy(i)}>{i.groups[0].external_id}</button> :
              // <button onClick={() => buy(i)}>Subscribe</button>
              <h6>Subscribe to Claim</h6>
          }
        </div>)
      // return <img   key={x} width={200} height={200} alt={i.sku}onClick={()=>buy(i)} src={i.image_url}/> 
    })
  }
  const renderDrops = () => {
    return (
      dropsData.length ?
        <>
          {
            dropsData.map((i: any, k: any) => {
              return (//"https://"+
                <a key={k} href={i.external_link}>
                  <h1>
                    {i.name}
                    <br />
                    <img height={250} src={i.image} />
                    {i.description}
                  </h1>
                </a>
              )
            }
            )
          }
        </> :
        <></>

      //<>No Drops {dropsData.length}</>
    )
  }
  const renderHeader = () => {
    if (projects.length > 0) {
      return renderMetamall()

    }


    return (
      dropsHeader?.length > 0 ?
        <>
          {/* {dropsHeader[0].image_url} */}

          <div className='' >
            <div className='flex-container-metamallProject'>
              <div className='metamallProjectCard' >
                <img className='metamallProject' src={dropsHeader[0].image_url} crossOrigin='anonymous' referrerPolicy="no-referrer" />
                {/* {i.media[0].url.endsWith("webp") || i.media[0].url.endsWith("jpg") || i.media[0].url.endsWith("png") ? <img src={i.media[0].url} className='metamallProject' /> : null}
              {i.media[0].url.endsWith("mov") ? <video src={i.media[0].url} loop autoPlay muted className='metamallProject' /> : null} */}


                <br />
                {/* {i.name} */}
                {subscriptionList && !subscriptionList.length ?
                  <IonButton onClick={subscribe}>Subscribe</IonButton> :
                  subscriptionList && subscriptionList.length ?
                    subscriptionList[0].status === "active" ?
                      <IonButton onClick={cancel}>Unsbscribe</IonButton> :
                      <br />//<IonButton onClick={renew}>Subscribe</IonButton>
                    : null


                }
                {subscriptionList?.length ?
                  <div>
                    Subscription Expires:&nbsp;
                    {(new Date(subscriptionList[0].date_next_charge).toLocaleDateString('en-US'))}
                  </div>
                  :
                  null}
                {/* {projects.length > 1 ? <button onClick={() => visit(i)}>Explore</button> : null} */}
              </div>
              <div className='metamallProjectCardDescription'  >
                {dropsHeader[0].description}
              </div>
              {/* <h1>{i.name}</h1>
            <br/>
            <h4>{i.description}</h4>
            <br/>
            {i.media[0].url.endsWith("webp")?<img src={i.media[0].url} />:null}
            {i.media[0].url.endsWith("mov")?<video src={i.media[0].url} controls  loop autoPlay muted className='metamallProject'/>:null} */}
            </div>



            <div className='metamallProjectCardDescription' slot='end' style={{ width: '100%', height: '100%' }}  >

            </div>

          </div>


        </> :
        conractData?.metaContract ?
          <>
            {/* <h1>
          
          </h1> */}

            <IonList>
              <IonItem>
                {/* <IonThumbnail slot="start"> */}
                <div slot="start" >
                  <img width={600} src={conractData?.metaContract.image} />
                  {/* </IonThumbnail> */}
                  {/* <IonLabel>   */}

                  {/* {conractData?.metaContract.description} */}
                  {/* </IonLabel> */}

                </div>
                <div slot="end">
                  <IonCard  >
                    <IonCardHeader>
                      <IonCardTitle> {conractData?.metaContract.name}</IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent>
                      {conractData?.metaContract.description}
                    </IonCardContent>
                  </IonCard>
                </div>


              </IonItem>
              <IonItem slot="end">

              </IonItem>
            </IonList>



          </> :
          <></>
    )
  }
  const renderGroups = () => {
    return items.map((i: any, k: any) => {
      // g.map((i:any,j:any)=>{
      return (
        //  <div onClick={() => buy(i)} className='card' key={k}>
        //    <img className='nft-img' alt={i.name} src={i.image_url} />
        //    <br />
        <div key={k}>
          <h1>{i.name}</h1>
          <br />
          <div className='flex-container-nft'>

            {renderItems(i.items.items)}
          </div>

        </div>
      )
      // return <img   key={x} width={200} height={200} alt={i.sku}onClick={()=>buy(i)} src={i.image_url}/> 
    }
    )
  }
  const renderMetamall = () => {
    return projects.length > 0 ? <>
      <h1>Discover</h1>
      <div className='flex-container-metamallProject'>
        {projects.map((i: any, k: any) => {
          return (<div className='' key={k}>

            <div className='metamallProjectCard'>
              {/* <img className='nft-img'  /> */}
              {i.media[0].url.endsWith("webp") || i.media[0].url.endsWith("jpg") || i.media[0].url.endsWith("png") ? <img src={i.media[0].url} className='metamallProject' /> : null}
              {i.media[0].url.endsWith("mov") ? <video src={i.media[0].url} loop autoPlay muted className='metamallProject' /> : null}

              <br />
              {i.name}
              <br />


              {projects.length > 1 ? <button onClick={() => visit(i)}>Explore</button> : null}
            </div>


            {/* <h1>{i.name}</h1>
            <br/>
            <h4>{i.description}</h4>
            <br/>
            {i.media[0].url.endsWith("webp")?<img src={i.media[0].url} />:null}
            {i.media[0].url.endsWith("mov")?<video src={i.media[0].url} controls  loop autoPlay muted className='metamallProject'/>:null} */}
          </div>

          )
        })
        }
        {projects.length === 1 ?
          <div className='metamallProjectCardDescription'  >
            {projects[0].description}
          </div> : null
        }
      </div>
    </> : null
  }
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>

          <Topbar />
          {/* {pubk.length ? <IonButtons slot="end"><IonButton routerLink='/auth/logout'>Logout</IonButton> </IonButtons > :<IonButtons slot="end"><IonButton routerLink='/auth/login'>Login</IonButton> </IonButtons >} */}
          {/* {pubk.length ? null :
            window.location.href.indexOf("login") < 0 ?
              <IonButtons slot="end"><IonButton routerLink='/auth/login'>Login</IonButton> </IonButtons >
              : null
          } */}
          <IonTitle>{name}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Claim</IonTitle>
          </IonToolbar>
        </IonHeader>
        {/* {stoken} */}
        {/* <br/>
        {items.length} */}
        {/* <br /> */}
        {/* {items.map((i:any,x:any)=>{
          return <img   key={x} width={100} height={100} alt={i.sku}onClick={()=>buy(i)} src={i.image_url}/> 
        })
        } */}
        {/* <div className='flex-container-nft'> */}
        {renderDrops()}
        {renderHeader()}
        {renderGroups()}
        { }



        {/* </div> */}

        {/* <ExploreContainer name={name} /> */}
      </IonContent>
    </IonPage>
  );
};

export default Page;


