import { IonButton, IonButtons, IonCol, IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonMenuButton, IonPage, IonRow, IonTitle, IonToggle, IonToolbar } from '@ionic/react';
// import { useParams } from 'react-router';
import './BulkUploadPage.css';

import React, { CSSProperties, useRef } from 'react';

import { RootState } from '../store';
// import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import axios from 'axios'
import { useHistory, useParams } from 'react-router';
import csvDownload from 'json-to-csv-export'
import { Guid } from 'js-guid';

import { useCSVReader } from 'react-papaparse';
import { useDispatch, useSelector } from 'react-redux';
import axiosRetry from "axios-retry"
import FormData from "form-data";

import sbabi from "../abi/soulboud.json"
import { ethers } from 'ethers';
import { refresh } from '../features/counter/profileSlice';


// import {create}  from 'ipfs';
// import makeIpfsFetch from 'ipfs-fetch';
import * as IPFS from 'ipfs-core'

import tokenMetadata from '../abi/TokenMetadata.json';
import { cloudUploadSharp } from 'ionicons/icons';
import ExploreContainer from '../components/ExploreContainer';
import Topbar from '../components/TopBar';
import path from 'path'
const styles = {
  csvReader: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 10,
  } as CSSProperties,
  browseFile: {
    width: '20%',
  } as CSSProperties,
  acceptedFile: {
    border: '1px solid #ccc',
    height: 45,
    lineHeight: 2.5,
    paddingLeft: 10,
    width: '80%',
  } as CSSProperties,
  remove: {
    borderRadius: 0,
    padding: '0 20px',
  } as CSSProperties,
  progressBarBackgroundColor: {
    backgroundColor: 'red',
  } as CSSProperties,
};

const BulkUploadPage: React.FC = () => {
  const ref = React.useRef<HTMLInputElement>(null);


  const stoken = useSelector((state: RootState) => state.profile.token)
  const { name } = useParams<{ name: string; }>();
  const { CSVReader } = useCSVReader();
  const [csvItems, setCsvItems] = useState([]);

  const dispatch = useDispatch()
  //const refresh = useSelector((state: RootState) => state.profile.refresh)
  const JWT = `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySW5mb3JtYXRpb24iOnsiaWQiOiI0MDFkZjVlMC1mODNhLTQyMDMtOGVjNi00NWJiNmZkZTk4YWEiLCJlbWFpbCI6ImFyc2VuM2RAZ21haWwuY29tIiwiZW1haWxfdmVyaWZpZWQiOnRydWUsInBpbl9wb2xpY3kiOnsicmVnaW9ucyI6W3siaWQiOiJGUkExIiwiZGVzaXJlZFJlcGxpY2F0aW9uQ291bnQiOjF9XSwidmVyc2lvbiI6MX0sIm1mYV9lbmFibGVkIjpmYWxzZSwic3RhdHVzIjoiQUNUSVZFIn0sImF1dGhlbnRpY2F0aW9uVHlwZSI6InNjb3BlZEtleSIsInNjb3BlZEtleUtleSI6ImM4YWUwNDFkNDMxZGRlMDVhZDllIiwic2NvcGVkS2V5U2VjcmV0IjoiNGY2NGIwMGJkZTY5ZDFkNTVmMDZlYTRiOGQ0OTg5ODgzNjU0YjQ1YzU5Y2NiNDk3OWM3MTQ1NWFmNGE4NTVmZiIsImlhdCI6MTY3MDk2NzQwOH0.CAx-o_c-3O8GWlDp_Ag3BRGzJ6gFuv0OYBtjmZwU5qY`
  const history = useHistory();
  const pk = useSelector((state: RootState) => state.profile.pk)
  const pubk = useSelector((state: RootState) => state.profile.pubk)
  const update = useSelector((state: RootState) => state.profile.refresh)
  const rpc = useSelector((state: RootState) => state.profile.rpc)
  const admin = useSelector((state: RootState) => state.profile.admin)
  const profile = useSelector((state: RootState) => state.profile)

  const metadata = useSelector((state: RootState) => state.site.metadata)


  const mintCollectionToggleRef = useRef<HTMLIonToggleElement>(null);
  const virtualItemToggleRef = useRef<HTMLIonToggleElement>(null);



  useEffect(() => {
    if (ref.current !== null) {
      ref.current.setAttribute("directory", "");
      ref.current.setAttribute("webkitdirectory", "");
    }
  }, [ref])
  const uploadToPinata = async (sourceUrl: string) => {
    console.log("sourceUrl", sourceUrl)
    const axiosInstance = axios.create();

    axiosRetry(axiosInstance, { retries: 5 });
    const data: any = new FormData();

    const response = await axiosInstance(sourceUrl, {
      method: "GET",
      responseType: "blob",
    });
    //data.append(`file`, response.data);
    const formData: any = new FormData();

    formData.append('file', response.data)

    const metadata = JSON.stringify({
      name: 'File name',
    });
    formData.append('pinataMetadata', metadata);

    const options = JSON.stringify({
      cidVersion: 0,
    })
    formData.append('pinataOptions', options);


    console.log("response.data", response.data)
    console.log("formData", formData.getAll("file"))
    //return;
    try {
      const res = await axios.post("https://api.pinata.cloud/pinning/pinFileToIPFS", formData, {
        //maxBodyLength: "Infinity",
        headers: {
          'Content-Type': `multipart/form-data;`,
          'Authorization': JWT
        }
      });
      console.log(res.data);
      return res.data.IpfsHash

    } catch (error) {
      console.log(error)
    }
  };
  const pinTokenUriJson = async (name: string, description: string, ipfs: string) => {
    var metadata = JSON.stringify({
      "pinataOptions": {
        "cidVersion": 1
      },
      "pinataMetadata": {
        "name": "testing",
        "keyvalues": {
          "customKey": "customValue",
          "customKey2": "customValue2"
        }
      },
      "pinataContent": {
        "name": name,
        "description": description,
        "url": "ipfs://" + ipfs,
        "animation_url": "",
        "external_url": "",
        "background_color": "",
        "youtube_url": ""
      }
    });

    var configMetadata = {
      method: 'post',
      url: 'https://api.pinata.cloud/pinning/pinJSONToIPFS',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': JWT
      },
      data: metadata
    };

    const metadataRes = await axios(configMetadata);

    console.log(metadataRes.data);
    return metadataRes.data.IpfsHash
  }

  const pinMetaData = async (item: any) => {
    var metadata = JSON.stringify({
      "pinataOptions": {
        "cidVersion": 1
      },
      "pinataMetadata": {
        "name": "testing",
        "keyvalues": {
          "customKey": "customValue",
          "customKey2": "customValue2"
        }
      },
      "pinataContent": {
        "name": item.name,
        "description": item.name,
        "url":  item.url,
        "animation_url": item.animation_url,
        "external_url": item.external_link,
        "background_color": "",
        "youtube_url": ""
      }
    });

    var configMetadata = {
      method: 'post',
      url: 'https://api.pinata.cloud/pinning/pinJSONToIPFS',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': JWT
      },
      data: metadata
    };

    const metadataRes = await axios(configMetadata);

    console.log(metadataRes.data);
    return metadataRes.data.IpfsHash
  }

  const abi = sbabi.output.abi;

  const mint = async (i: any, collectionAddress: string) => {
    const provider = new ethers.providers.JsonRpcBatchProvider(rpc);
    const signer = new ethers.Wallet(pk, provider)
    const nftContractReadonly = new ethers.Contract(collectionAddress, abi, signer); //profile.nft

    const ipfsHash = await uploadToPinata(i.image_url)
    const metadataIpfsHash = await pinTokenUriJson(i.name, i.description, ipfsHash)

    // setSidTokenUri("ipfs://" + metadataRes.data.IpfsHash)


    const result = await nftContractReadonly.safeMint(pubk, "ipfs://" + metadataIpfsHash)
    console.log(result)
    const receipt = (await result.wait())
    console.log(receipt);


  }

  const bulkMintCollection = async (contract: any, i: any, collectionAddress: string) => {


    const ipfsHash = await uploadToPinata(i.image_url)
    const metadataIpfsHash = await pinTokenUriJson(i.name, i.description, ipfsHash)

    // setSidTokenUri("ipfs://" + metadataRes.data.IpfsHash)


    const result = await contract.safeMint(pubk, "ipfs://" + metadataIpfsHash)
    console.log(result)
    const receipt = (await result.wait())
    console.log(receipt);

    //await Promise.all(transactions);
  }
  const sequenceMint = async (i: any, collectionAddress: string) => {


    try {
      const metadataIpfsHash = await pinMetaData(i);//pinTokenUriJson(i.name, i.description, ipfsHash)
      const provider = new ethers.providers.JsonRpcBatchProvider(rpc);
      const signer = new ethers.Wallet(pk, provider)
      const nftContractReadonly = new ethers.Contract(collectionAddress, abi, signer); //profile.nft

      const result = await nftContractReadonly.safeMint(pubk, "ipfs://" + metadataIpfsHash)
      console.log(result)
      const receipt = (await result.wait())
      console.log(receipt);
    }
    catch (e) {
      //stop
    }
    //await Promise.all(transactions);
  }
  const deployCollection = async (name: string) => {


    // Deploy the contract to Ethereum test network - Ropsten
    //const provider = ethers.providers.getDefaultProvider('ropsten')
    const provider = new ethers.providers.JsonRpcProvider(rpc);

    // Use your wallet's private key to deploy the contract
    const privateKey = pk
    const wallet = new ethers.Wallet(privateKey, provider)

    // Read the contract artifact, which was generated by Remix
    const metadata: any = tokenMetadata; //JSON.parse(tokenMetadata)

    // Set gas limit and gas price, using the default Ropsten provider
    const price = ethers.utils.formatUnits(await provider.getGasPrice(), 'gwei')
    const options = { gasLimit: 10000000, gasPrice: ethers.utils.parseUnits(price, 'gwei') }

    // Deploy the contract
    const factory = new ethers.ContractFactory(metadata.abi, metadata.data.bytecode.object, wallet)
    const contract = await factory.deploy(options)
    await contract.deployed()
    console.log(`Deployment successful! Contract Address: ${contract.address}`)
    if (virtualItemToggleRef.current?.checked) {
      createItemGroup(name, contract.address)
    }
    return contract.address;
  }

  const [selectedFile, setSelectedFile] = useState([]);
  let folderName = "";
  let contractAddress = "";
  let files: any = []
  const changeHandler = (event: any) => {
    folderName = event.target.files[0].webkitRelativePath.split("/")[0]

    console.log(event.target.files)
    setSelectedFile(event.target.files);
    files = event.target.files;
    setTimeout(handleSubmission, 1000)
  };
  const handleSubmission = async () => {

    const formData = new FormData();
    if (Array.from(files).length === 0) {
      window.alert("Folder Empty")
      return;
    }
    const collectionAddress = await deployCollection(folderName)
    let ipfs = await IPFS.create({ repo: 'ok' + Math.random() })
    //await ipfs.start()
    //if (mintCollectionToggleRef.current?.checked) {
    const provider = new ethers.providers.JsonRpcBatchProvider(rpc);
    const signer = new ethers.Wallet(pk, provider)
    const nftContractReadonly = new ethers.Contract(collectionAddress, abi, signer); //profile.nft
    //}


    let fileStructure: any = {};
    //await Array.from(files).forEach(async (file: any) => {
    for (let z = 0; z < files.length; z++) {
      let file: any = files[z]
      let list = await ipfs.add(file)
      if (fileStructure[path.parse(file.name).name] == undefined) {
        fileStructure[path.parse(file.name).name] = {};
        fileStructure[path.parse(file.name).name]["name"] = path.parse(file.name).name;
      }

      fileStructure[path.parse(file.name).name][path.parse(file.name).ext.substring(1)] = list.path;//file.name;
      //console.log(fileStructure)
      //fileStructure[path.parse(file.name).name]["cid"] = list.path;


      //console.log(fileStructure)
      // switch(fileStructure[path.parse(file).ext]){
      //   case "png":
      //     break;
      //   case "glb":
      //     break;
      //     case "fbx"
      // }

      formData.append("file", file)
      //let cid:any= await ipfs.refs(file)

      //console.log("IpfsHash list ", list)
      // for await (const x of list) {
      //   console.log(x);
      // }
      if (virtualItemToggleRef.current?.checked) {
        await uploadDigitalItemsToXsolla(file.name, list.path)
      }
      if (mintCollectionToggleRef.current?.checked) {
        //await run(file.name, list.path)

        /*
        var o: any = {};
        o.image_url = "https://files.xlapp.link/ipfs/" + list.path
        o.name = file.name;
        o.description = file.name;
        mints.push(o)
      */

        //await mint(o,collectionAddress)
        //await bulkMintCollection(nftContractReadonly,o,collectionAddress)

      }

      //mintData.push({ name: links[x].Name, description: links[x].Name, image_url: "https://ipfs.io/ipfs/" + links[x].Hash })
      //console.log(file.name)
      //Promise.all(mints)

    }
    //})
    console.log(fileStructure);

    const arrFileStructure = Object.values(fileStructure)
    console.log("arrFileStructure ", arrFileStructure)
    let mints: any = [];
    for (let x = 0; x < arrFileStructure.length; x++) {
      var o: any = {};
      var n: any = arrFileStructure[x];
      o.name = n.name;
      o.description = n.name;
      o.url = "ipfs://" + n.jpg
      o.animation_url = "ipfs://" + n.glb
      o.external_link = "ipfs://" + n.fbx
      mints.push(o)
    }

    console.log("mints ", mints)

    for (let x = 0; x < mints.length; x++) {
      await sequenceMint(mints[x], collectionAddress)
    }
    const siteCollection = new ethers.Contract(metadata.collection, abi, signer);
    //does it reah this?
    const m = await siteCollection.safeMint(pubk, "address://" + collectionAddress)
    await m.wait()
    dispatch(refresh())
    await ipfs.stop()
    //ipfs.st
    const metadataa = JSON.stringify({
      name: folderName,//'Folder name',
    });
    formData.append('pinataMetadata', metadataa);

    const options = JSON.stringify({
      cidVersion: 0,
    })
    formData.append('pinataOptions', options);
    let cid = "";
    try {
      const res = await axios.post("https://api.pinata.cloud/pinning/pinFileToIPFS", formData, {
        // maxBodyLength: "Infinity",
        headers: {
          'Content-Type': `multipart/form-data;`,
          'Authorization': JWT
        }
      });
      console.log(res.data);
      cid = res.data.IpfsHash
    } catch (error) {
      console.log(error);
    }

    //await ipfsFolderList(cid);//"QmbqobsDDLCUApnQyKVbk3LgmqRpAFYRxRhpYopMQQER2K")
    // not needed await csvDownload(dataToConvert);
    //await bulkMint(mintData)
    //await upload();
  };
  // const bulkMint = async (items: any) => {
  //   for (let x: number = 0; x < items.length; x++) {
  //     await mint(items[x]);
  //   }
  // }

  let mintData: any = [];
  const ipAddressesData = [
    {
      id: "1",
      name: "Sarajane Wheatman",
      ip: "40.98.252.240"
    },
    {
      id: "2",
      name: "Linell Humpherston",
      ip: "82.225.151.150"
    }
  ]

  const dataToConvert = {
    data: mintData,
    filename: 'bulk_mint',
    delimiter: ',',
    headers: ['name', "description", "image_url"]
  }

  const createItemGroup = async (name: string, address: string) => {
    contractAddress = address;
    const projectId = window.project_id;
    const resp = await fetch(

      `https://store.xsolla.com/api/v2/project/${projectId}/admin/items/groups`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          //Authorization: 'Basic ' + Buffer.from('a.yeremin@xsolla.com:e1j2X58E').toString('base64')
          "authorization": "Basic " + btoa(admin.merchant_id + ":" + admin.api)
        },
        body: JSON.stringify({
          name: {
            en: name
          },
          //image_url:"https://gateway.pinata.cloud/ipfs/"+ hash,
          //image_url: "https://ipfs.io/ipfs/" + hash,//"https://cloudflare-ipfs.com/ipfs/" + hash,
          groups: "folderName",
          isEnabled: true,
          external_id: address,
          order: 1,
          description: {
            en: name,
          },

          limits: {
            per_user: 5,
            per_item: 100
          }
        })
      }
    );
    console.log(resp)
  }
  const uploadDigitalItemsToXsolla = async (name: string, hash: string) => {
    const projectId = window.project_id;
    const resp = await fetch(
      `https://store.xsolla.com/api/v2/project/${projectId}/admin/items/virtual_items`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          //Authorization: 'Basic ' + Buffer.from('a.yeremin@xsolla.com:e1j2X58E').toString('base64')
          "authorization": "Basic " + btoa(admin.merchant_id + ":" + admin.api)
        },
        body: JSON.stringify({
          sku: Guid.newGuid().toString(),
          name: {
            en: name
          },
          //image_url:"https://gateway.pinata.cloud/ipfs/"+ hash,
          image_url: "https://files.xlapp.link/ipfs/" + hash,//"https://cloudflare-ipfs.com/ipfs/" + hash,
          groups: [contractAddress],//"bottles",
          is_enabled: true,
          is_free: true,
          order: 1,
          description: {
            en: name,
          },
          prices: [
            {
              amount: 100,
              currency: 'USD',
              is_enabled: true,
              is_default: true
            },
            {
              amount: 200,
              currency: 'CZK',
              country_iso: 'CZ',
              is_enabled: false,
              is_default: true
            }
          ],
          vc_prices: [],
          is_show_in_store: true,
          attributes: [
            {
              external_id: 'craft-materials',
              name: { en: 'Craft materials' },
              values: [
                {
                  external_id: 'steel',
                  value: { 'en-US': '5' }
                },
                {
                  external_id: 'leather',
                  value: { 'en-US': '1' }
                }
              ]
            }
          ],
          limits: {
            per_user: 5,
            per_item: 100
          }
        })
      }
    );

    const data = await resp.json();
    console.log(data);
  }
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <Topbar />
          <IonTitle>{name}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{name}</IonTitle>
          </IonToolbar>
        </IonHeader>
        {/* Bulk */}
        <CSVReader
          onUploadAccepted={(results: any) => {
            setCsvItems(results.data);
            console.log('---------------------------');
            console.log(results);
            console.log('---------------------------');
          }}
        >
          {({
            getRootProps,
            acceptedFile,
            ProgressBar,
            getRemoveFileProps,
          }: any) => (
            <>
              {/* <label className="form-label">choose Folder</label> */}
              {/* <button type='button' onClick={() => {
                ref.current?.form?.reset()
                // ref.current?.value; 
                //document?.getElementById(ref.current?.id)?.files = document.createElement('input').files;
                //console.log(ref.current?.value)
                ref.current?.click()
              }} style={styles.browseFile}>
                Browse Folder
              </button> */}
              <form>
                <input ref={ref} style={{ visibility: "hidden" }} type="file" onChange={changeHandler} />
              </form>

              {/* <button onClick={()=>deployCollection("group")}>deployCollection</button> */}
              {/* <button onClick={handleSubmission}>Submit</button>
              <br />
              <button onClick={() => { ipfsFolderList("cid") }}>List Files</button>
              <br />
              <button onClick={() => csvDownload(dataToConvert)}>
                Download Data
              </button>
              <br />

              <button onClick={() => { run("adidas", "QmbCPwPv1r8YNmsMPPfL4QEj9MqKX7cwMakrxP2mVxXXaX") }}>Create Item</button>

              <div style={styles.csvReader}>
                <button type='button' {...getRootProps()} style={styles.browseFile}>
                  Browse file
                </button>
                <div style={styles.acceptedFile}>
                  {acceptedFile && acceptedFile.name}
                </div>

                <button style={styles.remove} onClick={upload}>
                  Bulk Mint
                </button>

              </div> */}
              <ProgressBar style={styles.progressBarBackgroundColor} />
            </>
          )}
        </CSVReader>
        {
          csvItems.map((row, index) => {
            return (
              <div key={index}>
                {/* {row[0]} - {row[1]} - {row[2]} */}
                <br />
              </div>
            )
          }
          )
        }
        {/* <TopToolbar > */}
        {/* <CreateButton basePath={basePath} /> */}
        {/* <ImportButton  />
      </TopToolbar> */}
        {/* <List actions={<ListActions />}>
    <Datagrid>
      <TextField source="title" />
      <RichTextField source="body" />
     
    </Datagrid>
  </List> */}
        {/* <IonButton style={{width:700,height:700}} >
      
      </IonButton> */}
        <div className="container">

          {/* <IonRow className="ion-justify-content-center">
          <IonCol className="ion-justify-content-center"> */}

          <div>
            <IonButton style={{ width: "50%", height: "50%" }}
              onClick={() => {
                ref.current?.form?.reset()
                // ref.current?.value; 
                //document?.getElementById(ref.current?.id)?.files = document.createElement('input').files;
                //console.log(ref.current?.value)
                ref.current?.click()
              }}
            >
              <IonIcon style={{ width: "250px", height: "250px" }} size={"large"} icon={cloudUploadSharp}

              />

            </IonButton>
            <br />
            Upload Folder
            <IonList >
              <IonItem>
                <IonLabel>Mint Collectoin</IonLabel>
                <IonToggle slot="end" ref={mintCollectionToggleRef}></IonToggle>
              </IonItem>
              <IonItem>
                <IonLabel>Virtual Item</IonLabel>
                <IonToggle slot="end" ref={virtualItemToggleRef}></IonToggle>
              </IonItem>
            </IonList>

          </div>

          {/* </IonCol>
        </IonRow> */}
        </div>
        {/* <ExploreContainer name={name} /> */}
      </IonContent>
    </IonPage>
  );
};
export default BulkUploadPage;


