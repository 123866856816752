import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { useParams } from 'react-router';
// import './Template.css';

import { RootState } from '../store';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import axios from 'axios'
const Template: React.FC = () => {
  const stoken = useSelector((state: RootState) => state.profile.token)
  const { embed } = useParams<{ embed: string; }>();

  useEffect(() => {
    window.location.assign("https://login.xsolla.com/api/social/babka/login_redirect?login_url=https://login.arsenum.com/redirect/?"+ window.location.protocol + "//" + window.location.host +"/?&projectId=6a8800f2-4842-411e-850e-92df38663153&locale=en_US")
  }, [])
  //return (<>hi</>);
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          {/* <IonTitle>{name}</IonTitle> */}
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            {/* <IonTitle size="large">{name}</IonTitle> */}
          </IonToolbar>
        </IonHeader>
      {/* Babka {embed?
      <iframe 
      sandbox="allow-scripts allow-top-navigation"
      onLoad={(e:any)=>{
        console.log(e)
        alert(window.frames[0].location )
      }} src="https://login.xsolla.com/api/social/babka/login_redirect?login_url=https://login.arsenum.com/redirect/?http://localhost:3000/?&projectId=6a8800f2-4842-411e-850e-92df38663153&locale=en_US"></iframe>
      :"not"} */}
      </IonContent>
    </IonPage>
  );
};
export default Template;


