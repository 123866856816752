import { IonButton, IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { useParams } from 'react-router';
// import './Template.css';

import { RootState } from '../store';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import axios from 'axios'
import { ethers } from 'ethers';
import { loading, metadata } from '../features/counter/counterSlice';
import tokenMetadata from '../abi/TokenMetadata.json';
import { Exhibit,FloatingModel } from '@readyplayerme/visage';
import { Color, Vector3,Matrix4, Camera } from 'three';
import View3D from "@egjs/react-view3d";
import View3d from "..//components/View3d"
import Topbar from '../components/TopBar';
const BuyNftPage: React.FC = () => {
  const stoken = useSelector((state: RootState) => state.profile.token)
  const { address } = useParams<{ address: string; }>();
  const { tokenId } = useParams<{ tokenId: string; }>();
  const ipfsGateway = useSelector((state: RootState) => state.site.ipfsGateway)
  const rpc = useSelector((state: RootState) => state.profile.rpc)
  const pk = useSelector((state: RootState) => state.profile.pk)

  const [collections, setItems] = useState<any>([]);
  const dispatch = useDispatch()
  const fetchIpfs = async (uri: string) => {
    const metadata = await fetch(uri.replace("ipfs://", ipfsGateway));//"https://cloudflare-ipfs.com/ipfs/"));
    let meta = await metadata.json()
    console.log(meta)
    try {
      meta.url = meta.url.replace("ipfs://", ipfsGateway)//"https://cloudflare-ipfs.com/ipfs/")
      meta.animation_url = meta.animation_url.replace("ipfs://", ipfsGateway)
      meta.external_link = meta.external_link.replace("ipfs://", ipfsGateway)

    } catch (e) {
      console.log(e)
    }
    return meta;
  }
  useEffect(() => {
    dispatch(loading(true))
    setItems(Object.assign({}, {}))
    init()
  }, [tokenId])
  const init = async () => {


    /*
    const siteCollection = new ethers.Contract(site.metaContract.collection, tokenMetadata.abi, provider);
    console.log("site.metaContract.collection ", site.metaContract.collection)
    //setItems((await siteCollection.tokenURI(0)).toString())

    const collections = []
    const ownerAddress = await siteCollection.owner()
    const ownerBallance = await siteCollection.balanceOf(ownerAddress)
    for (let x = 0; x < parseInt(ownerBallance); x++) {
      const tokenOfOwnerByIndex = await siteCollection.tokenOfOwnerByIndex(ownerAddress, x)
      const address = (await siteCollection.tokenURI(tokenOfOwnerByIndex)).toString().split("://")[1]
      const collectionItem = new ethers.Contract(address, tokenMetadata.abi, provider);
      const nfts = []
      console.log("address ", address)

      const ownerBallance = await collectionItem.balanceOf(ownerAddress)
      const tokensInCollectionCount = parseInt((await collectionItem.tokenOfOwnerByIndex(ownerAddress, x)));
      console.log("tokensInCollectionCount ", tokensInCollectionCount)

      for (let y = 0; y < ownerBallance; y++) {
        const tokenOfOwnerByIndex = await collectionItem.tokenOfOwnerByIndex(ownerAddress, y)
        const nftTokenMetadata = await fetchIpfs((await collectionItem.tokenURI(tokenOfOwnerByIndex)).toString())
        nftTokenMetadata.tokenId = tokenOfOwnerByIndex.toString();
        nftTokenMetadata.address = address;
        nfts.push(nftTokenMetadata)
        setItems(Object.assign([], collections))
      }
      collections.push({ collectoin: address, tokenId: tokenOfOwnerByIndex.toString(), items: nfts })
      try {
      } catch (e) {

      }
     

    }
     */
  
  
    const provider = new ethers.providers.JsonRpcBatchProvider(rpc);
    const signer = new ethers.Wallet(pk, provider)
    const collection = new ethers.Contract(address, tokenMetadata.abi, signer);
    const nftTokenMetadata = await fetchIpfs((await collection.tokenURI(tokenId)).toString())
    setItems(Object.assign({}, nftTokenMetadata))
    
    //
  }
  const buyNft = async () =>{
    //buyNft
  }
  console.log(collections.animation_url)
  return (
    <IonPage>
 <IonHeader>
        <IonToolbar>
          <Topbar />
          {/* <IonTitle>{name}</IonTitle> */}
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{ }</IonTitle>
          </IonToolbar>
        </IonHeader>
        {/* Buy NFT {address} - {tokenId}b */}
        <div className='container' style={{height:"calc(100% - 150px)"}}>
        {/* {collections.animation_url?<Exhibit position={new Vector3(-5,0,-20 )}  modelSrc={collections.animation_url+"/?glb"} />:null} */}
        {/* {collections.animation_url?<Exhibit style={{top:300}} scale={.04}  position={new Vector3(0,0,10)}  modelSrc={collections.animation_url+"/?glb"} />:null} */}
        {/* {collections.animation_url?<Exhibit key={Math.random()} environment={"lobby"}  scale={.008} position={(new Vector3(1,0,1 ))}  style={{top:"0%", background:"transparent"}}   modelSrc={collections.animation_url+"/?glb"} />:null} */}
        {/* <View3d/>
        <br/> */}
        {collections?.animation_url?<View3D
        style={{width:"100%",height:"100%"}}
        // annotationSelector={}
        rotate={true}
        autoInit={true}
        autoplay={true}
        key={Math.random()}
          tag="div"
          src={collections.animation_url+"?.glb"}
          envmap="https://media.istockphoto.com/id/1365167838/photo/festive-beautiful-colorful-fireworks-display-on-the-sea-beach-amazing-holiday-fireworks-party.jpg?s=612x612&w=is&k=20&c=6AnlzRsg_-3-ssPfiXSELgUvWlPVh3PClriXTs0mDmE="
          onLoad={
            (e:any)=>{
              dispatch(loading(false))
            }
          }
          // onLoadFinish={}
          onReady={(e:any) => {
            console.log(e)
            
            // DO_SOMETHING
          }}
        />:null}
        <IonButton onClick={buyNft}>Buy</IonButton>
        </div>
        {/* <pre>
          {JSON.stringify(collections, null, 2)}
        </pre> */}
      </IonContent>
    </IonPage>
  );
};
export default BuyNftPage;



