import { IonButton, IonButtons, IonContent, IonHeader, IonInput, IonItem, IonLabel, IonList, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { useParams } from 'react-router';
import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
// import ExploreContainer from '../components/ExploreContainer';
import './LoginPage.css';
// import axios from 
import { useGetParamsMatch } from "../utils/useGetParamsMatch";
import { Router } from 'workbox-routing';
import { useHistory } from 'react-router-dom';
import { decrement, increment } from '../features/counter/counterSlice'
import { token, admin, pk, pubk, gas, refresh } from '../features/counter/profileSlice'
import { useSelector, useDispatch } from 'react-redux'

import { Widget } from '@xsolla/login-sdk'; //https://www.npmjs.com/package/@xsolla/login-sdk#tracking-widget-20-events
import * as ethers from "ethers";
import type { RootState } from '../store'
import { theme } from '@web3uikit/core/dist/lib/Accordion/types';


import { Web3AuthCore } from '@web3auth/core';
import {
  WALLET_ADAPTERS,
  CHAIN_NAMESPACES,
  SafeEventEmitterProvider,
} from '@web3auth/base';
import { OpenloginAdapter } from '@web3auth/openlogin-adapter';
//import { Web3Auth } from "@web3auth/modal";
import Web3 from "web3";
import RPC from './evm.ethers';

//let gprovider;
import { Web3Auth } from "@web3auth/modal";
import axios from 'axios';
import csvtojson from "csvtojson"
import registry from "../abi/registry.json"

import { whitelistUrl } from "@toruslabs/openlogin"
import { https } from 'follow-redirects'
const isip = require('isip');

//const {https} = require('follow-redirects');

//window.web3auth1:Web3AuthCore ;
let web3auth1: Web3AuthCore;
const LoginPage: React.FC = () => {
  const getLoginUrl = () => {
    return window.location.href;
    if (isip((window.location.hostname))) { //without port
      return window.location.protocol + "//" + window.location.host + "?site=" + window.location.protocol + "//" + window.location.host;;
    }
    //return window.location.protocol+"//login."+window.location.host.split(".").splice(1).join(".").toString()+ "?site=" +window.location.protocol+"//"+window.location.host;
    return window.location.protocol + "//" + window.location.host;//.split(".").splice(1).join(".").toString()+ "?site=" +window.location.protocol+"//"+window.location.host;

  }
  const mounted = useRef(false);
  const loginWrapperRef = useRef(null);
  const [loginWrapperClassName, setLoginWrapperClassName] = useState("invisible")
  const l = "";
  const xl = new Widget({
    projectId: "6a8800f2-4842-411e-850e-92df38663153",
    preferredLocale: 'en_US',
    //callbackUrl: window.location.protocol + "//" + window.location.host,// getLoginUrl()//window.location.protocol+"//"+window.location.host//href.split('/#/')[0]
    //callbackUrl:"https://login.arsenum.com"
    callbackUrl: "https://login.arsenum.com/redirect/?" + escape(window.location.protocol + "//" + window.location.host + "/?"),
    showPopupBackground: false
  });
  const site = useSelector((state: RootState) => state.site)
  //const profile = useSelector((state: RootState) => state.profile.profile)

  const rpcUrl = useSelector((state: RootState) => state.profile.rpc)
  const private_key = useSelector((state: RootState) => state.profile.pk)
  const profile = useSelector((state: RootState) => state.profile.profile)

  const configCSV = "https://docs.google.com/spreadsheets/d/e/2PACX-1vT-ieNhiOBaheuhoyma93tYd2pY6Ylzc_c-8z4-RjjrxLY8ZKFKKVZ9hBfHT256aizqsyHsth0HAahi/pub?output=csv"
  const clientId =
    "BJix88SAY8ohaAiin3tPHO6JVfy6XolRCS-tSPjWXZVumPCb-cBAH9vIOVWXIx5dP-xjl2obrdRcxvLxGHmwi-w";
  //"BHle4G5WJ6soVrhOxT8BkUJK9fBSdReuO03ixc_UetkDi7oYWv1o1YgcKwAVXnCDEqJw-Q6stITr8GNSlBUjAlg"
  //"BDRyT4sc-HCoQOi3JBJUP373cSAPeIt8uqorCVkJD4OcOuKdtoUWaOdtMKIeVbnKchYk6WTf6Yb_qt475JH9DaM"  
  //"BJix88SAY8ohaAiin3tPHO6JVfy6XolRCS-tSPjWXZVumPCb-cBAH9vIOVWXIx5dP-xjl2obrdRcxvLxGHmwi-w";//'BHr_dKcxC0ecKn_2dZQmQeNdjPgWykMkcodEHkVvPMo71qzOV6SgtoN8KCvFdLN7bf34JOm89vWQMLFmSfIo84A'; // get from https://dashboard.web3auth.io
  const cid =
    //"BHle4G5WJ6soVrhOxT8BkUJK9fBSdReuO03ixc_UetkDi7oYWv1o1YgcKwAVXnCDEqJw-Q6stITr8GNSlBUjAlg"
    "BJix88SAY8ohaAiin3tPHO6JVfy6XolRCS-tSPjWXZVumPCb-cBAH9vIOVWXIx5dP-xjl2obrdRcxvLxGHmwi-w";

  const domain_path = window.location.protocol + "//" + window.location.host
  const [loginId, setLoginId] = useState();
  //const match = useGetParamsMatch(['project_id', 'login_id'], snakeToCamel);

  const query = new URLSearchParams(useLocation().search);
  const history = useHistory();
  const stoken = useSelector((state: RootState) => state.profile.token);
  const ethAddress = useSelector((state: RootState) => state.profile.pubk);
  const update = useSelector((state: RootState) => state.profile.refresh);


  const simple = async () => {

    const web3auth = new Web3Auth({
      clientId: cid,

      chainConfig: { // this is ethereum chain config, change if other chain(Solana, Polygon)
        chainNamespace: CHAIN_NAMESPACES.EIP155,
        chainId: "0x5",

        rpcTarget: "https://mainnet.infura.io/v3/776218ac4734478c90191dde8cae483c",
      },
    });
    await web3auth.initModal();

    // call below line when user clicks on login button
    console.log(web3auth)
    if (web3auth.status == "connected") {
      web3auth.logout()
    }
    const provider = await web3auth.connect();


  }
  const gologin = async () => {
    try {
      console.log("web3auth1", web3auth1)
      if (web3auth1 != null) {
        console.log(query.get("token"))
        const web3authProvider = await web3auth1.connectTo(

          WALLET_ADAPTERS.OPENLOGIN,
          {
            loginProvider: 'jwt',
            extraLoginOptions: {

              id_token: query.get("token"),
              verifierIdField: 'sub',
              domain: "https://login.xsolla.com",
              scope: ""
              // response_type: "token"
            },
          },
        );
      }
    } catch (e) {
      console.log(e)
    }

  }
  const reg = async () => {
    let sig = await whitelistUrl("BJix88SAY8ohaAiin3tPHO6JVfy6XolRCS-tSPjWXZVumPCb-cBAH9vIOVWXIx5dP-xjl2obrdRcxvLxGHmwi-w", "135069d8eee9c4b7dd6b9e16399a6d25b554a278b369c63b205c53c19c04b5a2", domain_path)
    console.log(sig)
    console.log("clientId", clientId)
    try {
      web3auth1 = new Web3Auth({
        clientId: cid,
        enableLogging: true,

        chainConfig: {

          chainNamespace: CHAIN_NAMESPACES.EIP155,
          chainId: "0x1",
          //rpcTarget: "https://mainnet.infura.io/v3/776218ac4734478c90191dde8cae483c",
          blockExplorer: "https://etherscan.io/",
          ticker: "ETH",
          tickerName: "Ethereum"
        },
      });
      //alert("reg " + domain_path)
      const openloginAdapter = new OpenloginAdapter({
        adapterSettings: {
          clientId: cid,
          network: "testnet",
          uxMode: 'redirect',
          originData: { domain_path: sig },
          //storageKey: 'session',
          redirectUrl: getLoginUrl(),
          loginConfig: {
            jwt: {
              // name: 'Custom JWT Login',
              name: "any name",
              verifier: 'base64',
              typeOfLogin: 'jwt',
              clientId: cid,
            },
          },
        },
      });
      try {
        //web3auth1.clearCache()
        web3auth1.configureAdapter(openloginAdapter);


        await web3auth1.init()
        //await web3auth1.getUserInfo()
        //await web3auth1.getUserInfo()
        //await gologin()
        console.log("status", web3auth1.status)
        console.log("provider", web3auth1.provider)
        setWeb3auth(web3auth1)

      } catch (e) {
        console.log("error", e)
      }

      //setWeb3auth(web3auth1);

      ;
      console.log(web3auth1)
      if (web3auth1.provider) {
        setProvider(web3auth1.provider);
      }
    } catch (error) {
      console.error(error);
    }
  }
  let sig;
  useEffect(() => {
    //const followRedirect = require('follow-redirect-url');
    // (async () => {
    //   try {
    //     const r = await axios.get("https://login.xsolla.com/api/oauth2/auth?audience=X.la&client_id=3070&redirect_uri=https%3A%2F%2Flogin.xsolla.com%2Fapi%2Fsocial%2Foauth2%2Fcallback&response_type=code&scope=email&state=UwfjwTbO7wu4N_B-UTOEMGeI2WCzqZ14lpv3Z502dVA18Mm_eq_agX6_RXgiIEpYLFTuIr_iXld_obrr90HYzw")
    //   } catch (e: any) {
    //     console.log(e)
    //   }

    // })();

    // const request = https.request({
    //   host: 'login.xsolla.com',///api/social/babka/login_redirect?login_url=https://login.arsenum.com/redirect/?http://localhost:3000/?&projectId=6a8800f2-4842-411e-850e-92df38663153&locale=en_US',
    //   //path:'/api/oauth2/auth?audience=X.la&client_id=3070&redirect_uri=https%3A%2F%2Flogin.xsolla.com%2Fapi%2Fsocial%2Foauth2%2Fcallback&response_type=code&scope=email&state=7ez1-8-r1PkchP23VZ9ItqbPccXhE9DEvkT_nT-3qnGnDzH1pFvsmkNhdqsr4eVS4oaBnLSigolJxEtEGzRkOg'
    //   path: '/api/social/babka/login_redirect?http://localhost.localhost:3000&projectId=6a8800f2-4842-411e-850e-92df38663153&locale=en_US',
    //  // 6a8800f2-4842-411e-850e-92df38663153
    // }, (response:any) => {
    //   console.log(response.responseUrl);
    //   // 'http://duckduckgo.com/robots.txt'
    // },(err:any)=>{
    //   console.log(err)
    // });
    // request.end();
    // followRedirect.startFollowing('https://bit.ly/2X7gCIT').then((urls:any) => {
    //     console.log(urls);
    // }).catch((error:any) => {
    //     console.log(error)
    // })
    // if(site.metadata===null){
    //   return;
    // }
    // (async () => {
    //   const csv:any = await axios.get(configCSV)
    //   console.log(csv)
    //   const json = await csvtojson().fromString(csv.data)
    //   console.log(json)
    //   console.log(window.location.hostname)
    //   const item:any = json.find(i=>i.domain===window.location.hostname)
    //   console.log(item.id)
    // })();
    (async () => {
      sig = await whitelistUrl("BJix88SAY8ohaAiin3tPHO6JVfy6XolRCS-tSPjWXZVumPCb-cBAH9vIOVWXIx5dP-xjl2obrdRcxvLxGHmwi-w", "135069d8eee9c4b7dd6b9e16399a6d25b554a278b369c63b205c53c19c04b5a2", domain_path)
      console.log(domain_path)
      console.log(sig)
    })();
    // (async () => {
    //   const csv:any = await axios.get(configCSV)
    //   console.log(csv)
    //   const json = await csvtojson().fromString(csv.data)
    //   console.log(json)
    //   console.log(window.location.hostname)
    //   const item:any = json.find(i=>i.domain===window.location.hostname)
    //   console.log(item.id)
    // })();
    if (query.get("site")) {
      window.location.replace(query.get("site") + "?token=" + query.get("token"))
      console.log("site ", query.get("site"))
    }
    let t = query.get("token") || query.get("id_token") || query.get("result")
    if (t !== null && t !== "") {
      //init(t)
      dispatch(token(t))

      init(t)
      //setAdmin();
      setAddress()
      //return;
      // login1()

      //login succssful redirect t landing page
      history.push("/")
      //return;
    }
    if (!mounted.current) {
      xl.mount('xl_login');
      xl.on("@xsolla-login/sdk:open", (e) => {
        setLoginWrapperClassName("")
        //alert(e)
      })
      //alert(xl.eventNames[0])
      // xl.on(null,(e:any)=>{
      //   alert(e)
      // })
      // xl.setTheme({background:{image:{url:"",opacity:"100"},color:"red"}})
    }
    mounted.current = true;
    //console.log(setTimeout(()=>xl.setTheme({background:{color:"blue",image:{opacity:"1",url:""}}}),1000))

    // // return () => {
    // //     mounted.current = false;
    // // };

    // return;
    // if (stoken === "") {
    //   window.XL.init({
    //     projectId: "6a8800f2-4842-411e-850e-92df38663153",
    //     loginUrl: window.location.href.split('/#/')[0],
    //     locale: 'en_US',
    //     onlyWidgets: true,
    //     fields: 'email',
    //     theme: 'https://cdn3.xsolla.com/files/uploaded/15924/bfe7e2a5a75fb6f53d04de645ec7c542.css'
    //   });
    //   window.XL.on(window.XL.eventTypes.LOAD, function () {
    //     console.log('the widget has been loaded');

    //   });
    //   const element_id = 'xl_auth';
    //   const options = {
    //     'width': 600,
    //     'height': 550
    //   };
    //   window.XL.AuthWidget(element_id, options);
    // } else {
    //   history.push("/page/NFTs")
    // }
  }, [update]);

  // if (!loginId && match) {
  //   setLoginId(match.params.loginId);
  // }

  //const {projectId: defaultProjectId, loginId: defaultLoginId} = {};//config;
  // let url = window.location.href.split('/#/')[0] + '/#/';
  // let urlFull = `${url}?project_id=${defaultProjectId}&login_id=${defaultLoginId}`;

  const { name } = useParams<{ name: string; }>();
  const login = () => {
    //alert();
    //dispatch(increment())
    dispatch(token(""))
  }
  const logout = async () => {
    //alert();
    //dispatch(increment())
    dispatch(token(""))
    dispatch(pk(""))
    dispatch(pubk(""))
    try {

      let web3auth = new Web3AuthCore({
        clientId,
        chainConfig: {
          chainNamespace: CHAIN_NAMESPACES.EIP155,
          chainId: '0x5',///eth testnet
          //chainId: '0x144', //https://zksync2-testnet.zksync.dev	
          //rpcTarget: "https://api.zksync.io/jsrpc",//"https://rpc.ankr.com/eth_goerli",//"https://zksync2-mainnet.zksync.io",//"https://zksync2-testnet.zksync.dev",//"https://zksync2-testnet.zksync.dev", // EVM chain's RPC endpoint
          //rpcTarget: "https://mainnet.infura.io/v3/776218ac4734478c90191dde8cae483c",//`https://rpc.ankr.com/eth_goerli`,
          // Avoid using public rpcTarget in production.
          // Use services like Infura, Quicknode, Alchemy, Ankr etc.
          displayName: "zk", // EVM chain's Name
          blockExplorer: "https://etherscan.io/", // EVM chain's Blockexplorer
          ticker: "ETH", // EVM chain's Ticker
          tickerName: "ZK Beta", // EVM chain's Ticker Name
        },
      });

      const openloginAdapter = new OpenloginAdapter({
        adapterSettings: {
          clientId,
          network: 'testnet',
          uxMode: 'redirect',
          loginConfig: {
            jwt: {
              name: 'Custom JWT Login',
              //verifier: 'web3auth-core-custom-jwt',
              verifier: 'base64',
              typeOfLogin: 'jwt',
              clientId,
            },
          },
        },
      });

      // if(web3auth.status=='connected'){
      //   web3auth.
      // }

      web3auth.configureAdapter(openloginAdapter);
      //setWeb3auth(web3auth);

      await web3auth.init();
      if (openloginAdapter.status == 'connected') {
        // await web3auth.clearCache()
        await openloginAdapter.disconnect()
      }
      history.push("#")
    } catch (e) {
      console.log(e)
    }
    //stoken = "";
    //stoken = useSelector((state: RootState) => state.profile.token)
  }

  const [web3auth, setWeb3auth] = useState<Web3AuthCore | null>(null);
  const [provider, setProvider] = useState<SafeEventEmitterProvider | null>(
    null,
  );
  let w3p: any = null;
  const [zkBalance, setzkBalance] = useState("0");
  const [ethBalance, setEthBalance] = useState("0");
  const [privateKey, setPrivateKey] = useState("");
  const [publicAddres, setPublicAddres] = useState("");
  const [usdcBalance, setUSDCBalance] = useState("0");
  const [sagaBalance, setSagaBalance] = useState("0");
  const [connected, setConneted] = useState(false);

  const login1 = async () => {
    if (!web3auth) {
      console.log('web3auth not initialized yet');
      return;
    }

    const web3authProvider = await web3auth.connectTo(
      WALLET_ADAPTERS.OPENLOGIN,
      {
        loginProvider: 'jwt',
        extraLoginOptions: {
          id_token: window.location.href.split("=")[1].split("&")[0],//"eyJhbGciOiJSUzI1NiIsImtpZCI6IjY1MGY5ZGU4LWNkOTAtNGYzZS05YWU0LTUxMDA2NmEyMGU2MiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFyc2VuM2RAZ21haWwuY29tIiwiZXhwIjoxNjY5OTM3NDA4LCJncm91cHMiOlt7ImlkIjozNjE5OSwibmFtZSI6ImRlZmF1bHQiLCJpc19kZWZhdWx0Ijp0cnVlfV0sImlhdCI6MTY2OTg1MTAwOCwiaXNfbWFzdGVyIjp0cnVlLCJpc3MiOiJodHRwczovL2xvZ2luLnhzb2xsYS5jb20iLCJwcm9tb19lbWFpbF9hZ3JlZW1lbnQiOmZhbHNlLCJwdWJsaXNoZXJfaWQiOjM2NDY0MSwic3ViIjoiMDY0MDc0NmItYjQxNC00YTgyLTliYjctMzcxMTQ5OGY3YzZlIiwidHlwZSI6InNvY2lhbCIsInVzZXJuYW1lIjoiYXJzZW4zZEBnbWFpbC5jb20iLCJ4c29sbGFfbG9naW5fYWNjZXNzX2tleSI6IkhLX3BsTDhod212NlhCRjFhcE1Wa20xb3lha1hFWlF2TDhaNFJCWTU0ckUiLCJ4c29sbGFfbG9naW5fcHJvamVjdF9pZCI6IjZhODgwMGYyLTQ4NDItNDExZS04NTBlLTkyZGYzODY2MzE1MyJ9.a5MAbl6Xdr7J49K2pPUHkmX7W3xuM9zY0fTquyAI1O8P-9Dvp7ujSsrjAfC0IGslNlTdc19NHBxkh_qLPwPQ13gQEUgFwB-eMGohMqD0vDGZps2WVDFiuh3c7qNduPGaiTShlF58MjCWdHrq8h8jP9H-ROboHYn0EmYbS_LgD9aBkJdcy8ef7Kz2TLRAPLp99iRFFsWcoOQEcVJQRy-2vtohPhJ-mMM3mLujDdICMKqM26Lu8KCiN1G8sCmycyZ4em9lfpGYzoCRQi-e4fIC_4FYe60wRbyUhiNnH7F64ww0cpUkN5KQkk6BRdd4wDE3IO70-W9LVZx4ZZBw096QnQ",//await getIdToken(),
          verifierIdField: 'sub',
          domain: "https://login.xsolla.com",//'http://localhost:3000',
        },
      },
    );
    setProvider(web3authProvider);
    setConneted(true);
    console.log('Logged in Successfully!')
  };
  const init = async (ti: any) => {

    // if(JSON.parse(atob(ti.split(".")[1])).email.indexOf("xsolla.com")===-1 && !site.metadata.attributes.features.web3auth){
    //   return;
    // }
    //https://web3auth.io/docs/connect-blockchain/multi-chain
    dispatch(pubk("Loading wallet..."))

    // try {
    let t = query.get("token") || query.get("id_token") || query.get("result")
    const web3auth = new Web3Auth({
      clientId,
      chainConfig: {
        chainNamespace: CHAIN_NAMESPACES.EIP155,
        chainId: '0x5',///eth testnet
        //chainId: '0x144', //https://zksync2-testnet.zksync.dev	
        //rpcTarget: "https://api.zksync.io/jsrpc",//"https://rpc.ankr.com/eth_goerli",//"https://zksync2-mainnet.zksync.io",//"https://zksync2-testnet.zksync.dev",//"https://zksync2-testnet.zksync.dev", // EVM chain's RPC endpoint
        //rpcTarget: "https://mainnet.infura.io/v3/776218ac4734478c90191dde8cae483c",//`https://rpc.ankr.com/eth_goerli`,
        // Avoid using public rpcTarget in production.
        // Use services like Infura, Quicknode, Alchemy, Ankr etc.
        displayName: "zk", // EVM chain's Name
        blockExplorer: "https://etherscan.io/", // EVM chain's Blockexplorer
        ticker: "ETH", // EVM chain's Ticker
        tickerName: "ZK Beta", // EVM chain's Ticker Name
      },
    });
    let sig = await whitelistUrl("BJix88SAY8ohaAiin3tPHO6JVfy6XolRCS-tSPjWXZVumPCb-cBAH9vIOVWXIx5dP-xjl2obrdRcxvLxGHmwi-w", "135069d8eee9c4b7dd6b9e16399a6d25b554a278b369c63b205c53c19c04b5a2", domain_path)
    // alert("init " + domain_path + " " + sig)
    console.log("init " + domain_path + " " + sig)

    const openloginAdapter = new OpenloginAdapter({
      adapterSettings: {
        clientId,
        originData: { [domain_path]: sig },
        network: 'testnet',
        uxMode: 'redirect',
        storageKey: 'local',
        redirectUrl: domain_path,//getLoginUrl(),//window.location.href,
        whiteLabel: {
          dark: true
        },
        loginConfig: {
          jwt: {
            name: 'Custom JWT Login',
            //verifier: 'web3auth-core-custom-jwt',
            verifier: 'base64',
            typeOfLogin: 'jwt',
            clientId,
          },
        },
      },
    });
    // const openloginAdapter = new OpenloginAdapter({
    //   loginSettings: {
    //     mfaLevel: "optional",
    //   },
    //   adapterSettings: {
    //     network: "testnet",
    //     whiteLabel: {
    //       name: "Your app Name",
    //       logoLight: "https://web3auth.io/images/w3a-L-Favicon-1.svg",
    //       logoDark: "https://web3auth.io/images/w3a-D-Favicon-1.svg",
    //       defaultLanguage: "en",
    //       dark: true, // whether to enable dark mode. defaultValue: false
    //     },
    //   },
    // });
    // if(web3auth.status=='connected'){
    //   web3auth.
    // }

    web3auth.configureAdapter(openloginAdapter);
    await web3auth.init()
    //web3auth.configureAdapter()
    //setWeb3auth(web3auth);
    //web3auth.configureAdapter

    //await web3auth.initModal();

    // if(openloginAdapter.status=='connected'){
    //   await openloginAdapter.disconnect()
    // }
    //web3auth.connectTo
    //console.log("connecting to", t)
    if (true) {

      let p: any = {};
      // console.log(await web3auth.authenticateUser())
      // alert()
      if (web3auth.status !== 'connected') {
        const web3authProvider = await web3auth.connectTo(
          WALLET_ADAPTERS.OPENLOGIN,
          {

            // relogin: true,
            loginProvider: 'jwt',
            mfaLevel: "none",
            extraLoginOptions: {

              id_token: t, //window.location.href.split("=")[1].split("&")[0],//"eyJhbGciOiJSUzI1NiIsImtpZCI6IjY1MGY5ZGU4LWNkOTAtNGYzZS05YWU0LTUxMDA2NmEyMGU2MiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFyc2VuM2RAZ21haWwuY29tIiwiZXhwIjoxNjY5OTM3NDA4LCJncm91cHMiOlt7ImlkIjozNjE5OSwibmFtZSI6ImRlZmF1bHQiLCJpc19kZWZhdWx0Ijp0cnVlfV0sImlhdCI6MTY2OTg1MTAwOCwiaXNfbWFzdGVyIjp0cnVlLCJpc3MiOiJodHRwczovL2xvZ2luLnhzb2xsYS5jb20iLCJwcm9tb19lbWFpbF9hZ3JlZW1lbnQiOmZhbHNlLCJwdWJsaXNoZXJfaWQiOjM2NDY0MSwic3ViIjoiMDY0MDc0NmItYjQxNC00YTgyLTliYjctMzcxMTQ5OGY3YzZlIiwidHlwZSI6InNvY2lhbCIsInVzZXJuYW1lIjoiYXJzZW4zZEBnbWFpbC5jb20iLCJ4c29sbGFfbG9naW5fYWNjZXNzX2tleSI6IkhLX3BsTDhod212NlhCRjFhcE1Wa20xb3lha1hFWlF2TDhaNFJCWTU0ckUiLCJ4c29sbGFfbG9naW5fcHJvamVjdF9pZCI6IjZhODgwMGYyLTQ4NDItNDExZS04NTBlLTkyZGYzODY2MzE1MyJ9.a5MAbl6Xdr7J49K2pPUHkmX7W3xuM9zY0fTquyAI1O8P-9Dvp7ujSsrjAfC0IGslNlTdc19NHBxkh_qLPwPQ13gQEUgFwB-eMGohMqD0vDGZps2WVDFiuh3c7qNduPGaiTShlF58MjCWdHrq8h8jP9H-ROboHYn0EmYbS_LgD9aBkJdcy8ef7Kz2TLRAPLp99iRFFsWcoOQEcVJQRy-2vtohPhJ-mMM3mLujDdICMKqM26Lu8KCiN1G8sCmycyZ4em9lfpGYzoCRQi-e4fIC_4FYe60wRbyUhiNnH7F64ww0cpUkN5KQkk6BRdd4wDE3IO70-W9LVZx4ZZBw096QnQ",//await getIdToken(),
              verifierIdField: 'sub',
              //  domain: "https://login.xsolla.com",//'http://localhost:3000',
            },
          },
        );
        p = web3authProvider;
      }
      /*
w3p = web3auth.provider;
//await web3auth.connectTo
if (web3auth.provider) {
  //setProvider(web3auth.provider);

  const rpc = new RPC(web3auth.provider);
  // const ethBalance = await rpc.getBalance();
  // setPublicAddres((await rpc.getAccounts()))
  dispatch(pubk(await rpc.getAccounts()))

  // console.log("ethBalance", ethBalance)
  // setEthBalance(ethBalance);
  //todo make await, then use rpc
  //async getPrivateKey() {
  // const SolanaprivateKey = await web3auth.provider.request({
  //   method: "solanaPrivateKey"
  // });
  // console.log("SolanaprivateKey", SolanaprivateKey)
  //Do something with privateKey
  //}
  web3auth.provider.request({ method: "eth_private_key" }).then(async (r: any) => {
    console.log("private key ", r)
    dispatch(pk(r))
    const provider = new ethers.providers.JsonRpcProvider(rpcUrl);
    //const provider = new ethers.providers.JsonRpcProvider("http://a6941ec5d23214783af0b19eb8145e38-8bc7d71d20df28cf.elb.us-east-1.amazonaws.com:8545");
    const signer = new ethers.Wallet(r, provider)
    let xlabal = await signer.getBalance()
    dispatch(gas(xlabal.toString()))
    //console.log(r)
    setPrivateKey(r);
    //if(provider){
    setConneted(true)
    //}
    //setTimeout(()=>{connectZk()},3000);
    // connectZk()
    // connectSaga()

    //console.log(r)
  }).catch(e => {
    console.log("err", e.data)
  }).finally(() => {

  })

}
*/
    }
    // } catch (error) {
    //   console.error(error);
    // }
  };

  const count = useSelector((state: RootState) => state.site.value)

  const dispatch = useDispatch()
  //console.log(stoken)
  const setAdmin = () => {
    const merchant_id: any = prompt("Merchant ID", "")
    const api: any = prompt("API", "")
    dispatch(admin({ merchant_id: merchant_id, api: api }))
    //dispatch(token(""))
  }

  const setAddress = async () => {
    const resp = await fetch(
      `https://login.xsolla.com/api/attributes/users/me/update`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'e1j2X58E'
        },
        body: JSON.stringify({
          attributes: [
            {
              key: 'string',
              permission: 'public',
              value: 'string'
            }
          ],
          publisher_project_id: 0,
          removing_keys: ['string']
        })
      }
    );

    if (resp.status === 204) {
      console.log('success');
    } else {
      const data = await resp.json();
      console.log(data);
    }
  }


  const registerENS = async () => {
    const provider = new ethers.providers.JsonRpcProvider(rpcUrl);
    const wallet = new ethers.Wallet(private_key, provider)
    const price = ethers.utils.formatUnits(await provider.getGasPrice(), 'gwei')
    const options = { gasLimit: 10000000, gasPrice: ethers.utils.parseUnits(price, 'gwei') }

    // Deploy the contract
    const factory = new ethers.ContractFactory(registry.abi, registry.data.bytecode.object, wallet)
    const contract = await factory.deploy(options)
    await contract.deployed()
    console.log("Registry Deployed ", contract.address)
    // await contract.initialize(
    //   pubk,
    //   pubk,
    //   pubk,
    //   "false",
    //   "true",
    //   "100",
    //   "1",
    //   contract.address,
    //   [pubk],
    //   [10000],
    //   ['test'],
    // )

    //const reg = getContract(contract.address, "registry.json")
  }
  const getContract = (address: string, a: string) => {
    const file = require("../abi/" + a)
    console.log("loading contract: ", address)
    const abi = file.abi //JSON.parse(file).output.abi;
    const provider = new ethers.providers.JsonRpcProvider(rpcUrl);
    const signer = new ethers.Wallet(private_key, provider)

    const nftContractReadonly = new ethers.Contract(address, abi, signer);
    return nftContractReadonly;
  }
  const reload = async () => {
    dispatch(refresh())
  }
  return (
    <IonPage className='loginPage'>

      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{name}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen className='loginPage'>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{name}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <div className="container">
          <strong>{name}</strong>
          <div id="xl_auth"></div>
          <p>
            {/* Login {count} <pre>{JSON.stringify(parseJwt(stoken))}</pre> */}

            {/* <IonList>
      <IonItem>
        <IonLabel>Email input</IonLabel>
        <IonInput type="email" placeholder="email@domain.com"></IonInput>
      </IonItem>
      <IonItem>
        <IonLabel>Password input</IonLabel>
        <IonInput type="password" value="password"></IonInput>
      </IonItem>

      

      <IonItem>
      <IonButton onClick={login}>Login</IonButton>
      <IonButton disabled={true}>Register</IonButton>
      </IonItem>
    </IonList> */}
          </p>
          {profile != null && profile.email.indexOf("@xsolla.com") > 0 ? <>
            <IonButton onClick={logout}>Logout</IonButton>
            <br />
            <IonButton routerLink='/page/deploy/new'>License</IonButton>
            <br />
            <IonButton onClick={registerENS}>Init </IonButton>
            <br />
            <IonButton onClick={reload}>Reload </IonButton>
          </>
            : null}
          {/* {ethAddress} */}
        </div>
        {/* <ExploreContainer name={name} /> */}
        {/* {ethAddress} className={ethAddress === "" ? '' : 'none'}*/}

        {/* <IonButton onClick={init}>Init</IonButton>
        <IonButton onClick={reg}>reg</IonButton>
        <IonButton onClick={gologin}>gologin</IonButton>
        <IonButton onClick={simple}>simple</IonButton> */}
        {/* <br/>
        web3auth Status:{web3auth?.status}
        <br/> 
        provider stats {( provider===null)?"null":"ready"} */}


        {/* <IonButton onClick={login}>Register</IonButton> */}
        <div ref={loginWrapperRef} className={loginWrapperClassName}>
          <div id="xl_login" className={ethAddress === "" ? 'widget' : 'none'} ></div>
        </div>
        {/* <iframe  sandbox="allow-scripts allow-top-navigation"  onLoad={(e)=>console.log(e)} src='https://login.xsolla.com/api/oauth2/auth?audience=X.la&client_id=3070&redirect_uri=https%3A%2F%2Flogin.xsolla.com%2Fapi%2Fsocial%2Foauth2%2Fcallback&response_type=code&scope=email&state=UwfjwTbO7wu4N_B-UTOEMGeI2WCzqZ14lpv3Z502dVA18Mm_eq_agX6_RXgiIEpYLFTuIr_iXld_obrr90HYzw'></iframe> */}
        {/* <input type={"text"}/>
        <input type={"text"}/> */}
        {/* <IonButton onClick={setAdmin}>Admin</IonButton> */}
      </IonContent>
    </IonPage>
  );
};

export default LoginPage;
//https://developers.xsolla.com/doc/login/how-to/login-widget-sdk/
//https://github.com/orgs/Web3Auth/discussions/351
//https://medium.com/myetherwallet/hd-wallets-and-derivation-paths-explained-865a643c7bf2
//https://stackoverflow.com/questions/69248618/how-to-get-proper-public-address-from-mnemonic-phrase-for-solana
//https://web3auth.io/docs/sdk/web/providers/