import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'


export interface ProfileState {
  token: string
  profile: any
  admin:any
  pk:any
  pubk:any
  refresh:number
  gas:string
  rpc:string
  nft:string
  contractData:any
  dropsData:any[]
  keys:any
  wallet:any
  mnemonic:string
}
const initialState: ProfileState = {
  token: "",
  profile:null,
  admin:{},
  pk:"",
  pubk:"",
  refresh:0,
  gas:"0",
  rpc:"https://xla-1674265553-1.sagarpc.xyz",//"https://rpc.xlapp.link",
  nft:"0xaCD477E7d64f0493B07F13C2C104353C5fcc3EE6",
  contractData:{},
  dropsData:[],
  keys:{},
  wallet:null,
  mnemonic:""
}
export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    // increment: (state) => {
    //   // Redux Toolkit allows us to write "mutating" logic in reducers. It
    //   // doesn't actually mutate the state because it uses the Immer library,
    //   // which detects changes to a "draft state" and produces a brand new
    //   // immutable state based off those changes
    //   state.value += 1
    // },
    // decrement: (state) => {
    //   state.value -= 1
    // },
    // incrementByAmount: (state, action: PayloadAction<number>) => {
    //   state.value += action.payload
    // },
    refresh: (state) => {
      state.refresh += 1
      //state.refresh = state.refresh + action.payload;
    },
    gas: (state, action: PayloadAction<any>) => {
      state.gas = action.payload
    },
    rpc: (state, action: PayloadAction<any>) => {
      state.rpc = action.payload
    },
    pk: (state, action: PayloadAction<any>) => {
      state.pk = action.payload
    },
    pubk: (state, action: PayloadAction<any>) => {
      state.pubk = action.payload
    },
    contractData: (state, action: PayloadAction<any>) => {
      state.contractData = action.payload
    },
    dropsData: (state, action: PayloadAction<any>) => {
      state.dropsData = action.payload
    },
    keys: (state, action: PayloadAction<any>) => {
      state.keys = action.payload
    },
    wallet:(state, action: PayloadAction<any>) => {
      state.wallet = action.payload
    },
    mnemonic: (state, action: PayloadAction<any>) => {
      state.mnemonic = action.payload
    },
    
    admin: (state, action: PayloadAction<any>) => {
      state.admin = action.payload
      //console.log("sig",atob(action.payload.split('.')[2]))
    //   try {
    //     state.profile =  JSON.parse(atob(action.payload.split('.')[1]));
    //   } catch (e) {
    //     state.profile =   {}
    //   }
    //  console.log( state.profile);
    //  console.log( state.token);
    //  console.log("payload",action.payload)
    },
    token: (state, action: PayloadAction<string>) => {
      state.token = action.payload
      //console.log("sig",atob(action.payload.split('.')[2]))
      try {
        state.profile =  JSON.parse(atob(action.payload.split('.')[1]));
        state.profile.avatar = state.profile.picture.split("=")[0]
      } catch (e) {
        state.profile =   {}
      }
    //  console.log( state.profile);
    //  console.log( state.token);
    //  console.log("payload",action.payload)
    },
  },
})

// Action creators are generated for each case reducer function
export const {pk,pubk,token,admin,refresh,gas,contractData,dropsData,keys, mnemonic, wallet, rpc} = profileSlice.actions

export default profileSlice.reducer