import { IonAvatar, IonButtons, IonContent, IonHeader, IonIcon, IonItem, IonList, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { useParams } from 'react-router';
import './AnalyticsPage.css';

import { RootState } from '../store';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import Tree from 'react-d3-tree';

import axios from 'axios'

// import { useCenteredTree } from "./helpers";
import { ribbon } from 'ionicons/icons';
import Topbar from '../components/TopBar';

const AnalyticsPage: React.FC = () => {
  // const [t, containerRef] = useCenteredTree();
  // console.log(t)
  const stoken = useSelector((state: RootState) => state.profile.token)
  const { name } = useParams<{ name: string; }>();
  const orgChart = [
    {
      name: 'Collection',
      "id": "stock-of-the-United-States",
      attributes: {
        "Sent": '34,000',
      },
      children: [
        {
          name: 'Payments',

          attributes: {
            // "Sent": '$15,000',
          },
          children: [
            {
              name: 'Prepayment',
              // attributes: {
              //   department: 'Movies',
              // },
              children: [
                {
                  name: "Prepayment",
                  children: [
                    {
                      name: 'The Sales Agent Commission (“15% of the Collected Gross Receipts”)'
                    },
                    {
                      name: 'The Sales Agent MG ("USD75,000”);',
                    },
                    {
                      name: 'The Sales Agent Market Fee (“USD55,000’);',
                    },
                    {
                      name: 'To Sales Consultant in payment of the Sales Consultancy Fee (“(i) 4% of the ROW Territory Receipts and (ii) 4% of the Domestic Territory Receipts”);',
                    },
                    {
                      name: 'To Lender in recoupment of the Bridge Loan (“USD180,000 + USD36,000 interest”);',
                    },
                    {
                      name: 'To Lender 2 in recoupment of the Junior Production Loan ("USD63,500 + USD15,875',
                    },
                    {
                      name: 'Pro rata, pari passu, to the Equity Investors in recoupment of their respective Equity Investments (“Collectively, USD800,000”) plus 20% of their respective Equity Investments;',
                    },
                  ],
                },
                {
                  name: "Prepayment",
                  children: [
                    {
                      name: 'Deferee 1 USD7,200',
                    },
                    {
                      name: 'Deferee 2 USD4,000',
                    },
                    {
                      name: 'Deferee 3 USD5,000',
                    },
                    {
                      name: 'Deferee 4 USD46,300',
                    },
                    {
                      name: 'Deferee 5 USD27,500',
                    },
                    {
                      name: 'Deferee 6 USD10,000',
                    },
                  ],
                }
              ]
            },
            {
              name: 'Paralel Net Profits 50%',
              // attributes: {
              //   department: 'Shows',
              // },
              children: [
                {
                  name: 'Equity Investors 50%',
                  children: [
                    {
                      name: '(a) Investor 1 — 25%',
                    },
                    {
                      name: '(b) Investor 2 — 22%',
                    },
                    {
                      name: '(c) Investor 2 — 22%',
                    },
                    {
                      name: '(d) Investor 2 — 22%',
                    },
                    {
                      name: '(e) Investor 2 — 22%',
                    },
                    {
                      name: '(f) Investor 2 — 22%',
                    },
                    {
                      name: '(g) Investor 2 — 22%',
                    },
                    {
                      name: '(h) Investor 2 — 22%',
                    },
                    {
                      name: '(i) Investor 2 — 2.5%',
                    },
                    {
                      name: '(j) Investor 2 — 2%',
                    },
                    {
                      name: '(k) Investor 2 — 1.25%',
                    },
                  ],
                },
                {
                  name: 'Talent 50%',
                  children: [
                    {
                      name: 'Talent 10%',
                    },
                    {
                      name: 'Talent 10%',
                    },
                    {
                      name: 'Talent 10%',
                    },
                    {
                      name: 'Talent 10%',
                    },
                    {
                      name: 'Talent 5%',
                    },
                    {
                      name: 'Talent 2%',
                    },
                    {
                      name: 'Talent 2%',
                    },
                    {
                      name: 'Talent 2%',
                    },
                    {
                      name: 'Talent 2%',
                    },
                    {
                      name: 'Talent 2%',
                    },
                    {
                      name: 'Talent 2%',
                    },
                    {
                      name: 'Talent 2%',
                    },
                    {
                      name: 'Talent 2%',
                    },
                    {
                      name: 'Talent 2%',
                    },
                    {
                      name: 'Talent 2%',
                    },
                    {
                      name: 'Talent 5%',
                    },
                    {
                      name: 'Talent 2%',
                    },
                    {
                      name: 'Talent 2%',
                    },
                    {
                      name: 'Talent 2%',
                    },
                    {
                      name: 'Talent 2%',
                    },
                    {
                      name: 'Talent 2%',
                    },
                    {
                      name: 'Talent 2%',
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    }];
  const royalties = [
    {
      name: '',
      "id": "stock-of-the-United-States",
      attributes: {
        "Sent": '34,000',
      },
      children: [
        {
          name: "Licenseing"
        },
        {
          name: "Rentals",
          children: [
            {
              name: 'On Demand',
              children: [

                {
                  name: 'Netflix',
                },
                {
                  name: 'HBO Max',
                },
                {
                  name: 'Hulu',
                },
              ],
            }
          ]
        },
        {
          name: 'Royalties',

          attributes: {
            // "Sent": '$15,000',
          },
          children: [
            {
              name: 'Networks',
              // attributes: {
              //   department: 'Movies',
              // },
              children: [
                {
                  name: "Satalite",
                  children: [
                    {
                      name: 'Dish'
                    },

                  ],
                },
                {
                  name: "Cable",
                  children: [
                    {
                      name: 'Comcast',
                    },

                  ],
                }
              ]
            },
            {
              name: 'Internet',
              // attributes: {
              //   department: 'Shows',
              // },
              children: [
                {
                  name: 'On Demand',
                  children: [

                    {
                      name: 'Netflix',
                    },
                    {
                      name: 'HBO Max',
                    },
                    {
                      name: 'Hulu',
                    },
                  ],
                },
                {
                  name: 'Metaverse',
                  children: [


                    {
                      name: 'XLA Metaverse',
                    },
                  ],
                },
                {
                  name: 'Games',
                  children: [


                    {
                      name: 'Epic',
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    }];
  useEffect(() => {

  }, [])
  const containerStyles = {
    width: "100vw",
    height: "100vh"
  };
  const renderRectSvgNode = ({ nodeDatum, toggleNode }: any) => {
    // console.log(nodeDatum.children === undefined ? 1 : 0)
    let isGroup = false;
    isGroup = nodeDatum.children?.length ? true : false;;// !== undefined ? 1 : 0;
    return (
      <>
        <g>
          {/* <IonItem> */}
          {/* <IonIcon ios={ribbon} md={ribbon} /> */}
          {/* </IonItem> */}
          {/* <rect width="40" fill="gray" height="40" x="-20"  y="-15" onClick={toggleNode} /> */}
          <IonAvatar slot="start">
            {/* <img  src={profile.avatar}/> */}

          </IonAvatar>
          <svg></svg>
          {/* <div style={{height:200,width:200}}> */}
          {/* <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 6700 12020 520'><title>Ribbon</title><path d='M269 335.61q-6.33.47-12.78.47c-5.23 0-10.4-.24-15.51-.69a176.11 176.11 0 01-127.67-72.94 4 4 0 00-6.77.35l-72 129.4c-2.74 5-3.17 11-.28 15.88A16.78 16.78 0 0048.22 416h78a15.28 15.28 0 0113.62 7.33L178.5 488a16.26 16.26 0 0013.75 8c5.94-.33 12.09-4.19 14.56-9.6l66.11-145.15a4 4 0 00-3.92-5.64zM477.64 391.88l-71.53-129.17a4 4 0 00-6.74-.36 176.5 176.5 0 01-78.31 61.42 16.09 16.09 0 00-8.72 8.25l-36.86 81.1a7.92 7.92 0 000 6.6l30.27 66.59c2.45 5.41 8.59 9.36 14.52 9.69a16.3 16.3 0 0013.7-8.12l38.53-64.58c2.89-4.85 8.13-7.33 13.78-7.3h78.77c6.67 0 11.72-3.48 14-10a16.92 16.92 0 00-1.41-14.12z' /><ellipse cx='256.26' cy='160' rx='48.01' ry='48' /><path d='M256.26 16c-79.42 0-144 64.59-144 144s64.61 144 144 144 144-64.6 144-144-64.59-144-144-144zm0 224a80 80 0 1180-80 80.1 80.1 0 01-80 80z' /></svg> */}
          {/* </div> 
      transform="translate(-80.21022415161133,0)"
        */}
          {isGroup ?

            <text textAnchor={"start"} fill="green" strokeWidth="0" x="20" transform="translate(0,-10)">
              {nodeDatum.name}
            </text> :
            <>
              <circle className="nodeCircle" r="7" ></circle>
              <text fill="black" strokeWidth="0" x="20" textAnchor={"end"} transform="translate(-50,5)">
                {nodeDatum.name}
              </text>
            </>
          }
          {nodeDatum.attributes?.department && (
            null
            // <text fill="black" x="20" dy="20" strokeWidth="0">
            //   Department: {nodeDatum.attributes?.department}
            // </text>
          )}
        </g>
      </>
    )
  }
  const renderRightRectSvgNode = ({ nodeDatum, toggleNode }: any) => {
    // console.log(nodeDatum.children === undefined ? 1 : 0)
    let isGroup = false;
    isGroup = nodeDatum.children?.length ? true : false;;// !== undefined ? 1 : 0;
    return (
      <>
        <g>
          {/* <IonItem> */}
          {/* <IonIcon ios={ribbon} md={ribbon} /> */}
          {/* </IonItem> */}
          {/* <rect width="40" fill="gray" height="40" x="-20"  y="-15" onClick={toggleNode} /> */}
          <IonAvatar slot="start">
            {/* <img  src={profile.avatar}/> */}

          </IonAvatar>
          <svg></svg>
          {/* <div style={{height:200,width:200}}> */}
          {/* <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 6700 12020 520'><title>Ribbon</title><path d='M269 335.61q-6.33.47-12.78.47c-5.23 0-10.4-.24-15.51-.69a176.11 176.11 0 01-127.67-72.94 4 4 0 00-6.77.35l-72 129.4c-2.74 5-3.17 11-.28 15.88A16.78 16.78 0 0048.22 416h78a15.28 15.28 0 0113.62 7.33L178.5 488a16.26 16.26 0 0013.75 8c5.94-.33 12.09-4.19 14.56-9.6l66.11-145.15a4 4 0 00-3.92-5.64zM477.64 391.88l-71.53-129.17a4 4 0 00-6.74-.36 176.5 176.5 0 01-78.31 61.42 16.09 16.09 0 00-8.72 8.25l-36.86 81.1a7.92 7.92 0 000 6.6l30.27 66.59c2.45 5.41 8.59 9.36 14.52 9.69a16.3 16.3 0 0013.7-8.12l38.53-64.58c2.89-4.85 8.13-7.33 13.78-7.3h78.77c6.67 0 11.72-3.48 14-10a16.92 16.92 0 00-1.41-14.12z' /><ellipse cx='256.26' cy='160' rx='48.01' ry='48' /><path d='M256.26 16c-79.42 0-144 64.59-144 144s64.61 144 144 144 144-64.6 144-144-64.59-144-144-144zm0 224a80 80 0 1180-80 80.1 80.1 0 01-80 80z' /></svg> */}
          {/* </div> 
      transform="translate(-80.21022415161133,0)"
        */}
          {isGroup ?

            <text textAnchor={"end"} fill="green" strokeWidth="0" x="20" transform="translate(-40,-10)">
              {nodeDatum.name}
            </text> :
            <>
              <circle className="nodeCircle" r="7" ></circle>
              <text fill="black" strokeWidth="0" x="20" textAnchor={"start"} transform="translate(0,5)">
                {nodeDatum.name}
              </text>
            </>
          }
          {nodeDatum.attributes?.department && (
            null
            // <text fill="black" x="20" dy="20" strokeWidth="0">
            //   Department: {nodeDatum.attributes?.department}
            // </text>
          )}
        </g>
      </>
    )
  }
    ;
  //let x: any = t;
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <Topbar />
          <IonTitle>{name}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{name}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <div className="container" style={{ display: "flex", height: "100%" }}>
          <div style={{ width: "50%", background: "" }}>
            <Tree
              //translate={translate}
              //ref={containerRef}
              renderCustomNodeElement={renderRectSvgNode}
              separation={{ nonSiblings: 1.5, siblings: .25 }}
              orientation='horizontal'
              zoomable={false}
              // translate={{ x: 50, y: document.body.clientHeight / 2 }} 
              depthFactor={-140}
              translate={{ x: document.body.clientWidth / 2, y: document.body.clientHeight / 2 }}
              zoom={.5}

              data={orgChart}
              centeringTransitionDuration={1000}
              rootNodeClassName="node__root"
              branchNodeClassName="node__branch"
              leafNodeClassName="node__leaf" />
          </div>
          <div style={{ flexGrow: 1, background: "" }}>
            <Tree
              //translate={translate}
              //ref={containerRef}
              renderCustomNodeElement={renderRightRectSvgNode}
              separation={{ nonSiblings: 1.5, siblings: .25 }}
              orientation='horizontal'
              translate={{ x: 0, y: document.body.clientHeight / 2 }}
              zoomable={false}

              // depthFactor={-140}
              // translate={x}
              onUpdate={() => null}
              zoom={.5}
              data={royalties}
              centeringTransitionDuration={1000}
              rootNodeClassName="node__root"
              branchNodeClassName="node__branch"
              leafNodeClassName="node__leaf" />
          </div>
        </div>

        {/* License Royaltiy Analytics /  Cashflow Analytics */}

        <IonList >
          <IonItem >
            <div id="treeWrapper" >
              {/* renderCustomNodeElement={renderRectSvgNode} 
        https://bkrem.github.io/react-d3-tree/docs/interfaces/_src_tree_types_.treeprops.html#separation
        */}



            </div>
          </IonItem>

          <IonItem style={{ width: '50%', height: '100%' }} >
            <div slot='end' style={{ width: '100%', height: '100%' }}>

            </div>
          </IonItem>
        </IonList>
      </IonContent>
    </IonPage>
  );
};
export default AnalyticsPage;


