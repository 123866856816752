import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { ethers } from 'ethers'
import registry from '../../abi/registry.json';
import TokenMetadata from '../../abi/TokenMetadata.json';
import axios from 'axios';

export interface CounterState {
  value: number
  provider:any
  metadata:any
  ipfsGateway:string
  progressBar:boolean
}
// export interface TokenState {
//   value: string
// }
  const fetchIpfs =  (uri: string) => {
    const metadata = axios.get(uri.replace("ipfs://", "https://files.xlapp.link/ipfs/"));//"https://cloudflare-ipfs.com/ipfs/"));
    let meta = metadata;//await metadata.json()
    // try{
    //   meta.url = meta.url.replace("ipfs://", ipfsGateway)//"https://cloudflare-ipfs.com/ipfs/")

    // }catch(e){
    //   console.log(e)
    // }
    return meta;
  }
const siteMetadata =   () =>{
  const provider = new ethers.providers.JsonRpcProvider("https://xla-1674265553-1.sagarpc.xyz");
    const regContract = new ethers.Contract("0x19A3f98aBeEc1626161ECC179881D1BbB575CEeF", registry.abi, provider);
    let result: any;
    let p = (async ()=>{
     //try {
        //alert(window.location.protocol + "//" + window.location.hostname)
        result = await regContract.getAddressByName(window.location.protocol + "//" + window.location.hostname)//.split(".")[0]);//window.location.hostname)
        console.log(result)
        if(result.collection === "0x0000000000000000000000000000000000000000"){
          console.log("hostname not registered")
          return;
        }
        const tokenContract = new ethers.Contract(result.collection, TokenMetadata.abi, provider);
        const siteMetaDataUri = await  tokenContract.contractURI()
        let z =  fetchIpfs(siteMetaDataUri)
        console.log(z)
        return  z;
      // }catch(e){
      //   console.log(e)
      // }
       })();
      // let x = await p
      return p
}
const initialState: CounterState =  {
  value: 0,
  provider: new ethers.providers.JsonRpcProvider("https://xla-1674265553-1.sagarpc.xyz"),//"https://rpc.xlapp.link")
  metadata:  null,
  ipfsGateway:"https://files.xlapp.link/ipfs/",
  progressBar:false
}
export const counterSlice = createSlice({
  name: 'site',
  initialState,
  reducers: {
    increment: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value += 1
    },
    metadata:  (state, action: PayloadAction<any>) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      //console.log(action.pay)
      state.metadata = action.payload;
    },
    loading:  (state, action: PayloadAction<boolean>) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      //console.log(action.pay)
      state.progressBar = action.payload;
    },
    decrement: (state) => {
      state.value -= 1
    },
    incrementByAmount: (state, action: PayloadAction<number>) => {
      state.value += action.payload
    },
    // token: (state, action: PayloadAction<string>) => {
    //   state.value = action.payload
    // },
  },
})

// Action creators are generated for each case reducer function
export const { loading, metadata, increment, decrement, incrementByAmount } = counterSlice.actions

export default counterSlice.reducer